import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"


const myDbtpResultsSorter = (a, b) => {

    if (a.date === b.date) {
        return a.employee.person.last_name < b.employee.person.last_name ? -1 : 1
    } 
    return a.date < b.date ? 1 : -1
}



const doFetchMyDbtp = async (authtoken, from, till) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.mydbtp/?from='+from+'&till='+till,
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort( myDbtpResultsSorter )
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchMyDbtp = createAsyncThunk(
    "fetchMyDbtp",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const myDbtp = await doFetchMyDbtp(state.userInfo.token, state.myDbtp.from, state.myDbtp.till )
        if (myDbtp.success) {
            return myDbtp.data
        }

        return thunkAPI.rejectWithValue(myDbtp.error)
    }
)


export const slientFetchMyDbtp = createAsyncThunk(
    "slientFetchMyDbtp",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.myDbtp.current)) {
            return null
        }

        const myDbtp = await doFetchMyDbtp(state.userInfo.token, state.myDbtp.from, state.myDbtp.till )
        if (myDbtp.success) {
            return myDbtp.data
        }

        return thunkAPI.rejectWithValue(myDbtp.error)
    }
)




const myDbtpSlice = createSlice({
    name: "myDbtp",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null,
        from: dayjs(dayjs().format('YYYY-MM')+'01').subtract(1, 'month').format('YYYY-MM-DD'),
        till: dayjs().format('YYYY-MM-DD')
    },

    reducers: {
        setCustomRange(state, action) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.from = action.payload.from
            state.till = action.payload.till
        },
        unloadMyDbtp(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }

    },

    extraReducers: (builder) => {

        builder.addCase(fetchMyDbtp.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchMyDbtp.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchMyDbtp.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(slientFetchMyDbtp.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(slientFetchMyDbtp.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(slientFetchMyDbtp.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })

    },

})


export const { setCustomRange, unloadMyDbtp } = myDbtpSlice.actions

export default myDbtpSlice
