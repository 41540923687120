import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"

const teamVacationResultsSorter = (a, b) => {
    if ((a.ts_from === null) || (b.ts_from === null)) {
        if (a.ts_created === b.ts_created) return a.id > b.id ? -1 : 1
        return a.ts_created > b.ts_created ? -1 : 1
    }

    if (a.ts_from === b.ts_from) return a.id > b.id ? -1 : 1
    return a.ts_from > b.ts_from ? -1 : 1
}




const doFetchTeamVacation = async (authtoken) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.teamvacation/?year==' + dayjs().format('YYYY'),
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort(teamVacationResultsSorter)
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchTeamVacation = createAsyncThunk(
    "fetchTeamVacation",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const teamVacation = await doFetchTeamVacation(state.userInfo.token)
        if (teamVacation.success) {
            return teamVacation.data
        }

        return thunkAPI.rejectWithValue(teamVacation.error)
    }
)

export const silentFetchTeamVacation = createAsyncThunk(
    "silentFetchTeamVacation",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.teamVacation.current)) {
            return null
        }

        const teamVacation = await doFetchTeamVacation(state.userInfo.token)
        if (teamVacation.success) {
            return teamVacation.data
        }

        return thunkAPI.rejectWithValue(teamVacation.error)
    }
)



const teamVacationSlice = createSlice({
    name: "teamVacation",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null
    },

    reducers: {
        unloadTeamVacation(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }
    },

    extraReducers: (builder) => {

        builder.addCase(fetchTeamVacation.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchTeamVacation.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchTeamVacation.rejected, (state) => {
            state.loading = false
            state.error = true
        })


        builder.addCase(silentFetchTeamVacation.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchTeamVacation.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(silentFetchTeamVacation.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })


    },

})

export const { unloadTeamVacation } = teamVacationSlice.actions

export default teamVacationSlice