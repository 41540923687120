//localizer applied
import localizer from './../../localizer'

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { View } from 'react-native'

import TitleBar from '../TitleBar'
import { Appbar } from 'react-native-paper'  

import VacationsList from './VacationsList'
import HRequestsList from './HRequestsList'

const Tab = createMaterialTopTabNavigator()


export default function Requests(props) {
    
    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={localizer("Requests")}>
                <Appbar.Action icon="dots-vertical" onPress={() => { props.navigation.getParent().navigate('Settings') }} />
            </TitleBar>

            <Tab.Navigator>
                <Tab.Screen name={localizer("Vacations")} component={VacationsList} />
                <Tab.Screen name={localizer("Hourly Leaves")} component={HRequestsList} />
            </Tab.Navigator>
        </View>
    )

}