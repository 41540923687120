//localizer applied
import localizer from './../../localizer'

import { useState } from 'react'
import { FAB } from 'react-native-paper'
import { Platform } from 'react-native'

export default function RequestsFAB(props) {
    const [state, setState] = useState({ open: false });
    const onStateChange = ({ open }) => setState({ open });
    const { open } = state;

    return (
            <FAB.Group
                style={{ position: 'absolute', right: 5, bottom: Platform.OS === 'ios' ? -30 : 5 }}
                open={open}
                visible
                icon={open ? 'close' : 'plus'}
                actions={[
                    {
                        icon: 'clock-outline',
                        label: localizer('Hourly leave'),
                        onPress: () => props.navigation.getParent().getParent().navigate('HRequestEditor'),
                    },
                    {
                        icon: 'calendar',
                        label: localizer('Vacation'),
                        onPress: () => props.navigation.getParent().getParent().navigate('VacationEditor'),
                    },
                ]}
                onStateChange={onStateChange}
            />
    )

}