import { configureStore } from "@reduxjs/toolkit"
import userInfoSlice from "./userInfoSlice"
import appConfigSlice from "./appConfigSlice"
import myVacationSlice from "./myVacationSlice"
import myHRequestSlice from "./myHRequestSlice"
import myDbtpSlice from "./myDbtpSlice"
import teamDbtpSlice from "./teamDbtpSlice"
import teamVacationSlice from "./teamVacationSlice"
import teamHRequestSlice from "./teamHRequestSlice"
import teamVBalanceSlice from "./teamVBalanceSlice"
import calendarSlice from "./calendarSlice"

const store = configureStore({
  reducer: {
    userInfo: userInfoSlice.reducer,
    appConfig: appConfigSlice.reducer,
    myVacation: myVacationSlice.reducer,
    myHRequest: myHRequestSlice.reducer,
    myDbtp: myDbtpSlice.reducer,
    teamDbtp: teamDbtpSlice.reducer,
    teamVacation: teamVacationSlice.reducer,
    teamHRequest: teamHRequestSlice.reducer,
    teamVBalance: teamVBalanceSlice.reducer,
    calendar: calendarSlice.reducer,
  },
})

export default store