//localizer applied
import localizer from '../localizer'

import { useState } from 'react'       //React, 
import { StyleSheet, View, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native'
import { TextInput, Button, useTheme, Appbar } from 'react-native-paper'      //Appbar, Text, 
import { useDispatch } from "react-redux"       //useSelector

import Toast from 'react-native-root-toast'
import { logIn } from '../redux/userInfoSlice'          //fetchUserInfo
import TitleBar from './TitleBar'


export default function SignInForm() {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const themeObject = useTheme()

    const dispatch = useDispatch()

    /*
    async function signIn() {
        await new Promise(r => setTimeout(r, 550))
        Toast.show('Not working yet...')
    }
    */

    const signInAction = (argUsername, argPassword) => {
        dispatch(logIn({ username: argUsername, password: argPassword }))
            .then((resp) => {
                if (resp.type === 'logIn/rejected') {
                    Toast.show(localizer('Authentication failed') )
                } else {
                    Toast.show(localizer('Successfully signed in') )
                }
             } )
            .catch(() => {
                Toast.show(localizer('Authentication failed'))
            })
    }

    //themeObject.colors.background
    return (
        <TouchableWithoutFeedback onPress={() => { (Platform.OS === 'web') ? null : Keyboard.dismiss() }}>

            <View style={ { ...styles.outerContainer, backgroundColor: themeObject.colors.elevation.level2 } } >        

                <TitleBar>
                    <Appbar.Action icon="dots-vertical" disabled />
                </TitleBar>


                <View style={styles.container}>
                    <View style={styles.form}>
                        <View style={styles.formField}>
                            <TextInput
                                label={localizer("Username or email")}
                                autoCapitalize = {"none"}
                                value={login}
                                onChangeText={login => {
                                    setLogin(login)
                                }}
                            />
                        </View>

                        <View style={styles.formField}>
                            <TextInput
                                label={localizer("Password")}
                                autoCapitalize = {"none"}
                                secureTextEntry={true}
                                value={password}
                                onChangeText={password => {
                                    setPassword(password)
                                }}
                            />
                        </View>

                        <Button mode="contained" style={styles.btn} onPress={ () => { signInAction(login, password) } } >{localizer("Sign-In")}</Button>


                    </View>
                </View>
            </View>

        </TouchableWithoutFeedback>
    )

}


const styles = StyleSheet.create({
    outerContainer: {
        height: '100%',
        width: '100%'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },


    form: {
        alignItems: 'center',
        width: '100%',
        paddingBottom: 200,
    },
    formField: {
        marginHorizontal: 10,
        marginTop: 20,
        height: 60,
        width: '90%',
    },
    btn: {
        marginTop: 25,
        minWidth: 200
    },


})



