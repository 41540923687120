import { View } from 'react-native'
import { Text, Switch } from 'react-native-paper'


export default function HeaderSwitch(props) {


    


    return (
        <View style={{height: 35, marginTop: 6, marginBottom: 3 }}>
            <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
                <Text style={{ alignItems: 'center', fontWeight: 'bold', textAlign: 'left', marginLeft: 15, flex: 3}}>{ props.label }</Text>
                <Switch style={{ marginRight: 20 }} value={ props.enabled } onValueChange={ () => { props.setter( !props.enabled ) } } />
            </View>
        </View>
    )
}