import { View } from 'react-native'
import { IconButton, useTheme } from 'react-native-paper'


export default function DbtpRequestsComponent(props) {

    const themeObject = useTheme()
    const dModel = props.dbtpModel

    return(
        <View style={{ flex: 1, marginLeft: 10, marginTop: -27, flexDirection: 'row-reverse' }}>
        { dModel.requestModels.map( requestModel => {
            const navigationTarget = requestModel.data.class === 'vacation' ? 'VacationEditor' : 'HRequestEditor'
            const key = (requestModel.data.class === 'vacation' ? 'v' : 'hr')+requestModel.data.id

            let mode='contained-tonal'
            let color = themeObject.colors.onSurfaceVariant
            let backgroundColor = themeObject.colors.surfaceVariant

            switch(requestModel.statusDesc) {
                case 'rejected':
                    color = themeObject.colors.onError
                    backgroundColor = themeObject.colors.error
                    break
                case 'cancelled':
                    color = themeObject.colors.onTertiary
                    backgroundColor = themeObject.colors.tertiary
                    break
                case 'pending':
                    color = themeObject.colors.onPrimary
                    backgroundColor = themeObject.colors.primary
                    break
            }

            return (
                <IconButton key={key} mode={ mode } iconColor={ color } style={{ backgroundColor: backgroundColor }} icon={requestModel.icon} onPress={ () => { props.navigation.navigate(navigationTarget, { model: requestModel }) }} />
                )
        }) }
    </View>
    )

}