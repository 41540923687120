import store from "./redux/store"
import { unloadCalendar } from "./redux/calendarSlice"
import { unloadMyDbtp } from "./redux/myDbtpSlice"
import { unloadMyHRequest } from "./redux/myHRequestSlice"
import { unloadMyVacation } from "./redux/myVacationSlice"
import { unloadTeamDbtp } from "./redux/teamDbtpSlice"
import { unloadTeamHRequest } from "./redux/teamHRequestSlice"
import { unloadTeamVacation } from "./redux/teamVacationSlice"
import { unloadTeamVBalance } from "./redux/teamVBalanceSlice"

import { silentFetchUserInfo } from "./redux/userInfoSlice"     //fetchUserInfo
import { silentFetchCalendar } from "./redux/calendarSlice"
import { slientFetchMyDbtp } from "./redux/myDbtpSlice"
import { silentFetchMyHRequest } from "./redux/myHRequestSlice"
import { silentFetchMyVacation } from "./redux/myVacationSlice"
import { silentFetchTeamDbtp } from "./redux/teamDbtpSlice"
import { silentFetchTeamHRequest } from "./redux/teamHRequestSlice"
import { silentFetchTeamVacation } from "./redux/teamVacationSlice"
import { silentFetchTeamVBalance } from "./redux/teamVBalanceSlice"

import { AppState } from 'react-native'

import dayjs from "dayjs"

export const unloadDataStores = () => {
    store.dispatch(unloadCalendar())
    store.dispatch(unloadMyDbtp())
    store.dispatch(unloadMyHRequest())
    store.dispatch(unloadMyVacation())
    store.dispatch(unloadTeamDbtp())
    store.dispatch(unloadTeamHRequest())
    store.dispatch(unloadTeamVacation())
    store.dispatch(unloadTeamVBalance())
}


export const refetchUserInfo = () => {
    store.dispatch(silentFetchUserInfo())
}

export const inspectUserInfoStore = async () => {
    const userInfo = store.getState().userInfo

    const maxAge = AppState.currentState === 'active' ? 1000*60*4 : 1000*60*30
    
    if (userInfo.token !== null) {
        const age = dayjs().valueOf() - userInfo.lastUpdate

        if (age > maxAge) {
            //console.log('dispatching...')
            await store.dispatch(silentFetchUserInfo())
            await store.dispatch(silentFetchCalendar())
            await store.dispatch(slientFetchMyDbtp())
            await store.dispatch(silentFetchMyHRequest())
            await store.dispatch(silentFetchMyVacation())
            await store.dispatch(silentFetchTeamDbtp())
            await store.dispatch(silentFetchTeamHRequest())
            await store.dispatch(silentFetchTeamVacation())
            await store.dispatch(silentFetchTeamVacation())
            await store.dispatch(silentFetchTeamVBalance())
        }
    }  

    setTimeout(() => {
        inspectUserInfoStore()
      }, 1000*60*2)
}

