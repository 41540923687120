//import { enGB, pl } from 'react-native-paper-dates'
import { enGB, pl } from '../custom_modules/react-native-paper-dates'


export const rnpdLocales = {
    en: { name: 'en', tr: enGB },
    cn: { name: 'en', tr: enGB },
    pl: { name: 'pl', tr: pl }
}

import dayjs from 'dayjs'
import plDayjsLocale from 'dayjs/locale/pl'
import enDayjsLocale from 'dayjs/locale/en'

export const setDayjsLocale = (localeToUse) => {
    if (!localeToUse) {
        localeToUse = global.locale
    }

    switch (localeToUse) {
        case 'cn':
        case 'en': dayjs.locale(enDayjsLocale)
            break
        case 'pl': dayjs.locale(plDayjsLocale)
            break
    }
}


export const locale = {
    en: {
        '(rn)pending': 'pending',
        '(rn)cancelled': 'cancelled',
        '(rn)rejected': 'rejected',
        '(rn)confirmed': 'confirmed',

        '(rż)pending': 'pending',
        '(rż)cancelled': 'cancelled',
        '(rż)rejected': 'rejected',
        '(rż)confirmed': 'confirmed',
    },
    pl: {
        'this is test string': 'to jest tesowy ciąg znaków',
        'Loading...': 'Wczytywanie...',
        'Home': 'Startowa',
        'Calendar': 'Kalendarz',
        'Requests': 'Wnioski',
        'My Team': 'Mój Zespół',
        'Successfully logged out': 'Wylogowanie zakończone powodzeniem',
        'Application language changed': 'Zmieniono język aplikacji',
        'Application language': 'Język aplikacji',
        'Settings': 'Ustawienia',
        'Log out': 'Wyloguj',
        'Authentication failed': 'Logowanie nie powiodło się',
        'Successfully signed in': 'Udane logowanie',
        'Username or email': 'Nazwa użytkownika lub email',
        'Password': 'Hasło',
        'Sign-In': 'Zaloguj',
        'Update required': 'Wymagana aktualizacja',
        'You are using version': 'Używasz wersji',
        'SmartBPMA administrators marked this build': 'Administratorzy SmartBPMA oznaczyli tę kompilację',
        'as no longer compatibilie with system servers.': 'jako niekompatybilną z serwerami systemu.',
        'Please access SmartBPMA via web and follow': 'Zaloguj się proszę do SmartBPMA przez przeglądarkę i postępuj',
        'the instructions how to update.': 'zgodnie z instrukcją aktualizacji.',
        'Sign Out': 'Wyloguj',
        'My Dashboard': 'Mój Pulipt',
        'Team Attendance': 'Obecność Zespołu',
        'No check-in on record yet.': 'Nie zarejestrowano rozpoczęcia pracy.',
        'You checked in at:': 'Rozpoczęcie pracy o:',
        'have a productive day!': 'udanego dnia pracy!',
        'You worked:': 'Przepracowno:',
        'have a nice evning!': 'dobrego popołudnia!',
        'Welcome,': 'Witaj,',
        'child care hours left': 'pozostało godzin opieki',
        'vacation days left': 'pozostało dni urlopu',
        'You are off today, enjoy!': 'Dziś wolne, udanego wypocznku!',
        'Your workschedule for today:': 'Twój plan pracy na dziś:',
        'within': 'pomiędzy',
        'overtime balance': 'blians nadgodzin',
        'private leave balance': 'bilans wyjść prywatnych',
        'No requests placed for today.': 'Brak wniosków na dziś.',
        'Requests for today:': 'Wnioski na dziś:',
        'No entries found': 'Brak wpisów',
        'days': 'dni',
        'No items to show': 'Brak pozycji do wyświetlenia',
        'day off for January 1st': 'wolne za 1 stycznia',
        'Easter': 'Wielkanoc',
        'Constitution Day': 'Święto Konstytucji',
        'Corpus Christi': 'Boże Ciało',
        'Assumption Day': 'Wniebowzięcie NśMP',
        'All Saints': 'Wszystkich Świętych',
        'Independence Day': 'Święto Niepodległości',
        'Christmas': 'Boże Narodzenie',
        'Epiphany': 'Objawienie Pańskie',
        'Easter Monday': 'Poniedziałek Wielkanocny',
        'day off for Independence Day': 'dzień wolny za Dzień Niepodległości',
        '2nd Day of Christmas': '2gi dzień Bożego Narodzenia',
        'Labour Day': 'Święto Pracy',
        'Nothing found': 'Nic nie znaleziono',
        'Choose an option': 'Wybierz opcję',
        'No requests found': 'Nie znaleziono wniosków',
        'Vacations': 'Urlopy',
        'Hourly Leaves': 'Wnioski Godzinowe',
        'Vacation': 'Urlop',
        'Hourly leave': 'Wniosek godzinowy',
        'Balance': 'Bilans',
        'Mon': 'Pon',
        'Tue': 'Wt',
        'Wed': 'Śr',
        'Thu': 'Czw',
        'Fri': 'Pt',
        'Sat': 'Sob',
        'Sun': 'Niedz',
        'No balance info found': 'Nie znaleziono bliansów',
        'Employee Name': 'Pracownik',
        'Child Care': 'Opieka',
        'Overtimes': 'Nadgodziny',
        'Leaves': 'Wyjścia',
        'show pending requests only': 'pokaż tylko wnioski oczekujące',
        'Date is required': 'Data jest wymagana',
        'Time range is reqired': 'Przedział czasowy jest wymagany',
        'Invalid time range': 'Niepoprawny przedział czasowy',
        'Give/get back date is required': 'Data odpracowania/odebrania jest wymagana',
        'Give/get back time range is reqired': 'Przedział czasowy dla odpracowania/odebrania jest wymagany ',
        'Invalid time range': 'Niepoprawny przedział czasowy',
        'Request placed': 'Wniosek złożony',
        'Request updated': 'Wniosek zmieniony',
        'Operation failed for unknown reason.': 'Operacja nie powiodła się z nieznanych przyczyn',
        'Request cancelled': 'Wniosek anulowany',
        'Request approved': 'Wniosek zatwierdzony',
        'Request rejected': 'Wniosek odrzucony',
        'Supervisor comment updated': 'Komentarz przełożonego zmieniony',
        'Request type': 'Typ wniosku',
        'From time': 'Od godziny',
        'Till time': 'Do godziny',
        'Date': 'Data',
        'Comment': 'Komentarz',
        'Supervisor comment': 'Komentarz przełożonego',
        'HR comment': 'Komentarz działu HR',
        'Give/get back request': 'Wniosek o odpracowanie/odebranie',
        'Give/get back date': 'Data odpracowania/odebrania',
        'Place Request': 'Złóż Wniosek',
        'Cancel': 'Anuluj',
        'Update': 'Zmień',
        'Reject': 'Odrzuć',
        'Approve': 'Zatwierdź',
        'Update Comment': 'Popraw Komentarz',
        'Select date': 'Wybierz datę',
        'Apply': 'Zastosuj',
        'Select time': 'Wybierz godzinę',
        'none or specify later': 'brak lub wprowadzę później',
        'not specified or default': 'domyślna lub nie wybrano',
        'Date range is required': 'Wymagany jest zakres dat',
        'Deputy employee': 'Osoba na zastępstwo',
        'Date range': 'Zakres dat',
        'Select period': 'Wybierz zakres',
        'from': 'od',
        'till': 'do',

        'vacation request': 'urlop',
        'unpaid vacation request': 'urlop bezpłatny',
        'on-demand vacation request': 'urlop na żądanie',
        'occasional holiday request': 'urlop okolicznościowy',
        'child care request': 'opieka nad dzieckiem',
        'sickleave': 'zwolnienie lekarskie',
        'unexcused absence event': 'nieobecność nieusprawiedliwiona',
        'excused absence event': 'nieobcność niepłatna',
        'excused paid absence event': 'nieobecność płatna',
        'home office request': 'wniosek o home office',
        'business trip request': 'wyjazd służbowy',
        'add balance system event': 'przynanie limitu',
        'adjust balance system event': 'korekta limitu',
        'other event': 'inny wpis lub zdarzenie',
        'Other event': 'Inny wpis lub zdarzenie',
        'balance event': 'wpis związany z limitem',

        'in effect': 'zarejestrowany',
        'pending': 'oczekujący',
        'cancelled': 'anulowany',
        'rejected': 'odrzucony',
        'confirmed': 'potwiedzony',

        'Unknown Context': 'Nieznany Kontekst',
        'Event related to me': 'Zapis związany ze mną',
        'Event related to my employee': 'Zapis mojego pracownika',
        'New vacation request': 'Nowy wniosek urlopowy',
        'Request pending to me': 'Oczekuje na moją decyzję',
        'request': 'wniosek',
        'My': 'Mój',
        'Please sepcify a deputy.': 'Proszę wskazać osobę na zastępstwo',
        'Unable to detect active workschedule for specified time frame.': 'We wskazanym przedziale czasu nie wykryto godzin pracy.',
        'Insufficent vacation balance to place this request.': 'Niewystarczający limit urlopu do złożenia tego wniosku.',
        'Insufficent ondemand vacation balance to place this request.': 'Niewystarczający limit urlopu na żądanie do złożenia tego wniosku.',
        'Please sepcify date range correctly.': 'Proszę poprawnie określić przedział dat.',
        'This vacation request is no longer cancellable.': 'Wniosek uległ zmianie, anulowanie nie jest już możliwe.',
        'This vacation request is no longer user manageable.': 'Wniosek uległ zmianie, edycja nie jest już możliwa.',

        '(rn)pending': 'oczekujące',
        '(rn)cancelled': 'anulowane',
        '(rn)rejected': 'odrzucone',
        '(rn)confirmed': 'potwierdzone',

        '(rż)pending': 'oczekująca',
        '(rż)cancelled': 'anulowana',
        '(rż)rejected': 'odrzucona',
        '(rż)confirmed': 'potwierdzona',


        'private leave': 'wyjście prywatne',
        'give back for leave/lateness': 'odpracowanie wyjścia prywatnego',
        'overtime': 'nadgodziny',
        '1:1 get back for overtime': '1:1 odbiór nadgodzin',
        '1:1.5 get back for overtime': '1:1.5 odbiór nadgodzin',
        'paid absence': 'nieobecność płatna',
        'unpaid absence': 'nieobecność niepłatna',
        'check in/out missing': 'brak odbicia',
        'child care': 'opieka nad dzieckiem',
        'lateness': 'spóźnienie',
        'hourly business leave': 'wyjście służbowe',
        //'other event': 'inny wpis lub zdarzenie',

        'New hourly request': 'Nowy wniosek godzinowy',
        'hourly request': 'wniosek godzinowy',
        'Hourly request pending to me': 'Oczekuje na moją decyzję',
        
        'Child care can only be requested in full hours.': 'Wniosek o opiekę musi zostać zaokrąglony do pełnych godzin.',
        'Insufficient child care balance left, unable to proceed.': 'Niewystarczający limit godzin opieki na dziecko.',
        'Correct date is required.': 'Wymagana jest poprawna data',
        'Valid give/get back date is required.': 'Wymagana jest poprawna data odpracowania/odbioru.',
        'Please provide valid time period.': 'Proszę wprowadzić poprawny przedział czasowy.',
        'Please provide valid give/get back time period.': 'Proszę wprowadzić poprawny przedział czasowy dla odpracowania/odbioru.',
        'Comment is required for overtime and give back requests.': 'Dla wniosków o nadgodziny lub odpracowanie wymagany jest komentarz.',
        'Please provide give back comment.': 'Proszę wprowadzić komentarz dla odpracowania/odbioru.',
        'me': 'mój wniosek',


        'Notification preferences changed': 'Zmieniono ustawienia powiadomień',
        'Notifications': 'Powiadomienia',
        'enabled': 'włączone',
        'disabled': 'wyłączone',
        'Please grant this app notifications permissions first': 'Napierw proszę nadać tej aplikacji uprawnienia do powiadomień',
        'Notification permissions removed, disabling notifications': 'Powiadomienia zostały wyłączone, gdyż odebrano uprawnienia',

        'one vacation request': 'jeden wniosek urlopowy',
        'vacation requests': 'wnioski urlopowe',
        'one hourly request': 'jeden wniosek godzinowy',
        'hourly requests': 'wnioski godzinowe',
        'is pending your review.': 'oczekuje na Twoją decyzję.',
        'are pending your review.': 'oczekują na Twoją decyzję.',

        'fractional vacation': 'urlop ułamkowy',
        'No fractional vacation left, unable to proceed.': 'Brak ułamkowej części urlopu do wykorzystania.',
        'No active workschedule for specified day, unable to proceed.': 'Wybrany dzień nie zawiera godzin pracy.',
        'Request time need to match fractional vacation precisely.': 'Wnioskowany czas musi pokrywać się z ułamkową częścią urlopu.',
        
        /*
        //can be obtained from server:
        'Employee id mismatch.'
        //--'This vacation request is no longer cancellable.'
        //--'This vacation request is no longer user manageable.'
        'Employee id mismatch.'
        'Type is either not specified, not allowed or invalid.'
        'Invalid deputy sepcified.'
        'Invalid vacation request sepcified.'
        'Specified vacation request not confirmation pending.'
        'Specified vacation request not subject to confirmation.'
        'Invalid approval decision.'
        'Type is either not specified or invalid.'
        'Invalid backtype specified.'
        'This request is no longer editable.'
        'Invalid hourly request sepcified.'
        'Invalid approval decision.'
        */
    },
    cn: {
        'this is test string': '這個是測試字符',
        'Loading...': '加載中',
        'Home': '主頁',
        'Calendar': '日曆',
        'Requests': '提出需求',
        'My Team': '我的團隊',
        'Successfully logged out': '成功登出',
        'Application language changed': '申請語言已更改',
        'Application language': '申請語言',
        'Settings': '設置',
        'Log out': '登出',
        'Authentication failed': '認證失敗',
        'Successfully signed in': '成功登入',
        'Username or email': '用戶名或郵件',
        'Password': '密碼',
        'Sign-In': '登錄',
        'Update required': '需要更新',
        'You are using version': '您正在使用版本',
        'SmartBPMA administrators marked this build': 'SmartBPMA 管理員標記了這個版本',
        'as no longer compatibilie with system servers.': '不再與系統服務器兼容',
        'Please access SmartBPMA via web and follow': '請通過網頁或流程訪問SmartBPMA',
        'the instructions how to update.': '說明如何更新',
        'Sign Out': '登出',
        'My Dashboard': '我的儀錶盤',
        'Team Attendance': '團隊考勤',
        'No check-in on record yet.': '還沒有入住記錄',
        'You checked in at:': '您簽到於',
        'have a productive day!': '富有成效的一天！',
        'You worked:': '您曾工作於:',
        'have a nice evning!': '晚上好！',
        'Welcome,': '歡迎',
        'child care hours left': '剩餘托兒時間',
        'vacation days left': '剩餘的假期',
        'You are off today, enjoy!': '你今天休息，享受每一天!',
        'Your workschedule for today:': '您今天的工作安排：',
        'within': '之內',
        'overtime balance': '加班餘額',
        'private leave balance': '個人假期餘額',
        'No requests placed for today.': '今天沒有請求',
        'Requests for today:': '今天的請求',
        'No entries found': '沒有找到該條目',
        'days': '天',
        'No items to show': '沒有可顯示的項目',
        'day off for January 1st': '1月1日休息',
        'Easter': '復活節',
        'Constitution Day': '憲法日',
        'Corpus Christi': '科珀斯克里斯蒂',
        'Assumption Day': '假設日',
        'All Saints': '諸聖節',
        'Independence Day': '獨立日',
        'Christmas': '聖誕節',
        'Epiphany': '頓悟節',
        'Easter Monday': '復活節星期一',
        'day off for Independence Day': '獨立日休息一天',
        '2nd Day of Christmas': '聖誕節第二天',
        'Labour Day': '勞動節',
        'Nothing found': '什麼都沒找到',
        'Choose an option': '請選擇一個選項',
        'No requests found': '未找到請求',
        'Vacations': '假期',
        'Hourly Leaves': '每小時休假',
        'Vacation': '假期',
        'Hourly leave': '小時假',
        'Balance': '平衡',
        'Mon': '星期一',
        'Tue': '星期二',
        'Wed': '星期三',
        'Thu': '星期四',
        'Fri': '星期五',
        'Sat': '星期六',
        'Sun': '星期天',
        'No balance info found': '未找到餘額信息',
        'Employee Name': '員工姓名',
        'Child Care': '育兒',
        'Overtimes': '加班',
        'Leaves': '休假',
        'show pending requests only': '僅顯示待處理的請求',
        'Date is required': '日期是必需的',
        'Time range is reqired': '時間範圍是必需的',
        'Invalid time range': '時間範圍無效',
        'Give/get back date is required': '給/取回日期是必需的',
        'Give/get back time range is reqired': '給/取回時間範圍是必需的',
        'Invalid time range': '時間範圍無效',
        'Request placed': '提出要求',
        'Request updated': '請求已更新',
        'Operation failed for unknown reason.': '操作因未知原因失敗。',
        'Request cancelled': '請求已取消',
        'Request approved': '請求批准',
        'Request rejected': '請求被拒絕',
        'Supervisor comment updated': '主管評論已更新',
        'Request type': '請求類型',
        'From time': '從時間',
        'Till time': '直到時間',
        'Date': '日期',
        'Comment': '評論',
        'Supervisor comment': '主管意見備註',
        'HR comment': '人資意見備註',
        'Give/get back request': '給出/取回請求',
        'Give/get back date': '給出/取回日期',
        'Place Request': '發出請求',
        'Cancel': '取消',
        'Update': '更新',
        'Reject': '拒絕',
        'Approve': '批准',
        'Update Comment': '更新評論',
        'Select date': '選擇日期',
        'Apply': '申請',
        'Select time': '選擇時間',
        'none or specify later': '無或稍後指定',
        'not specified or default': '未指定或默認',
        'Date range is required': '必須填寫日期範圍',
        'Deputy employee': '代理人',
        'Date range': '日期範圍',
        'Select period': '選擇時期',
        'from': '從',
        'till': '直到',

        'vacation request': '請假',
        'unpaid vacation request': '無薪休假申請',
        'on-demand vacation request': '按需請假',
        'occasional holiday request': '臨時請假',
        'child care request': '托兒請求',
        'sickleave': '病假',
        'unexcused absence event': '無故缺席事件',
        'excused absence event': '有據缺席事件',
        'excused paid absence event': '帶薪缺勤事件',
        'home office request': '居家辦公要求',
        'business trip request': '出差申請',
        'add balance system event': '添加餘額系統事件',
        'adjust balance system event': '調整平衡系統事件',
        'other event': '其他事件',
        'Other event': '其他活動',
        'balance event': '平衡事件',

        'in effect': '有效',
        'pending': '待辦的',
        'cancelled': '取消',
        'rejected': '拒絕',
        'confirmed': '確認的',

        'Unknown Context': '未知上下文',
        'Event related to me': '與我有關的事件',
        'Event related to my employee': '與我的員工相關的事件',
        'New vacation request': '新的休假申請',
        'Request pending to me': '請求等待我',
        'request': '要求',
        'My': '我的',
        'Please sepcify a deputy.': '請指定一名代理人',
        'Unable to detect active workschedule for specified time frame.': '無法檢測指定時間範圍內的活動工作計劃。',
        'Insufficent vacation balance to place this request.': '假期餘額不足，無法提出此請求。',
        'Insufficent ondemand vacation balance to place this request.': '按需假期餘額不足，無法提出此請求。',
        'Please sepcify date range correctly.': '請正確指定日期範圍。',
        'This vacation request is no longer cancellable.': '此休假請求不再可取消。',
        'This vacation request is no longer user manageable.': '此休假請求不再是用戶可管理的。',

        '(rn)pending': '待定',
        '(rn)cancelled': '取消',
        '(rn)rejected': '拒絕',
        '(rn)confirmed': '確認',

        '(rż)pending': '待定',
        '(rż)cancelled': '取消',
        '(rż)rejected': '拒絕',
        '(rż)confirmed': '確認',


        'private leave': '私假',
        'give back for leave/lateness': '請假/遲到歸還',
        'overtime': '隨著時間的推移',
        '1:1 get back for overtime': '1:1加班返還',
        '1:1.5 get back for overtime': '1:1.5 加班返還',
        'paid absence': '帶薪缺勤',
        'unpaid absence': '無薪缺勤',
        'check in/out missing': '簽入/簽出缺失',
        'child care': '育兒',
        'lateness': '遲到',
        'hourly business leave': '每小時商務假',
        //'other event': '其他活動',

        'New hourly request': '新的每小時請求',
        'hourly request': '每小時請求',
        'Hourly request pending to me': '每小時請求等待確認',
        
        'Child care can only be requested in full hours.': '托兒服務只能在全時段申請。',
        'Insufficient child care balance left, unable to proceed.': '剩餘的托兒餘額不足，無法繼續。',
        'Correct date is required.': '需要正確的日期。',
        'Valid give/get back date is required.': '需要有效的退還日期。',
        'Please provide valid time period.': '請提供有效時間段。',
        'Please provide valid give/get back time period.': '請提供有效的給予/收回時間段。',
        'Comment is required for overtime and give back requests.': '加班和工時返還請求需要備註合理理由',
        'Please provide give back comment.': '請提供反饋意見。',
        'me': '我',


        'Notification preferences changed': '通知首選項已更改',
        'Notifications': '通知',
        'enabled': '啟用',
        'disabled': '禁用',
        'Please grant this app notifications permissions first': '請先授予此應用通知權限',
        'Notification permissions removed, disabling notifications': '刪除通知權限，禁用通知',

        'one vacation request': '一個休假請求',
        'vacation requests': '休假申請',
        'one hourly request': '一個小時的請求',
        'hourly requests': '每小時請求',
        'is pending your review.': '正在等待您的審核。',
        'are pending your review.': '正在等待您的審核。',

        'fractional vacation': '部分假期',
        'No fractional vacation left, unable to proceed.': '沒有剩餘的小數假期，無法繼續。',
        'No active workschedule for specified day, unable to proceed.': '指定日期沒有活動工作計劃，無法繼續。',
        'Request time need to match fractional vacation precisely.': '請求時間需要精確匹配小數假期。',
        }
}


export default function localizer(str, replacePairs) {
    let tr = str

    if (locale[global.locale]) {
        if (locale[global.locale].hasOwnProperty(str)) {
            tr = locale[global.locale][str]
        } else if (global.locale !== 'en') {
            //console.log(global.locale+' locale string missing')
            console.log("'" + str + "': '',")
        }
    }

    if (typeof replacePairs !== 'undefined') {
        for (const [varname, varvalue] of Object.entries(replacePairs)) {
            tr = tr.replace('%' + varname + '%', varvalue)
        }
    }

    return tr
}