//localizer applied
import localizer from './../localizer'

import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

import axios from "axios"
import { unloadDataStores, refetchUserInfo } from '../dispatchers'


export default class hrequestModel {

    #date = null
    #durationMs = null

    constructor(data, userInfo = null) {
        this.data = data
        this.userInfo = userInfo

        this.draft()
    }

    insertUserInfo(userInfo) {
        if (this.userInfo === null) {
            this.userInfo = userInfo
        }

        //this.draft()
    }

    get hasData() {
        return this.data instanceof Object && Object.keys(this.data).length ? true : false
    }

    get hasUserInfo() {
        return this.userInfo instanceof Object && Object.keys(this.userInfo).length ? true : false
    }

    get debug() {
        return {
            class: 'hrequestModel',
            hasData: this.hasData,
            hasUserInfo: this.hasUserInfo,
            id: this.hasData ? this.data.id : null,
            isDraft: this.isDraft,
        }
    }

    get isMy() {
        if (!this.hasUserInfo) return null
        return this.userInfo.employee.id === this.data.employee_id ? true : false
    }

    get isEditable() {
        return this.isMy && this.isPending ? true : false
    }

    get isPending() {
        return this.data.confirmed === 0 ? true : false
    }

    get isManagedByMe() {
        if (!this.hasUserInfo) return null
        return this.userInfo.manages_employee_ids.includes(this.data.employee_id) ? true : false
    }

    get isPendingToMe() {
        if (!this.hasUserInfo) return null
        return this.isPending && this.isManagedByMe ? true : false
    }

    draft() {
        if (((!this.hasData) || (!this.data.hasOwnProperty('id'))) && (this.hasUserInfo)) {
            this.data = {
                class: 'hrequest',
                id: null,
                //ts_created: null
                employee_id: this.userInfo.employee.id,
                type: 1,
                ts_date: null,
                comment: null,
                hrcomment: null,
                supcomment: null,  
                confirmed: 0,
                //confirmed_user_id: null
                //ts_confirmed: null,
                //extra_info: null
                //overtime_confirmed: 0,
                from_time: null,    //"1430"
                till_time: null,
                ...this.data
            }
        }
    }

    get isDraft() {
        return (this.hasData && this.data.id !== null && this.data.id !== 0) ? false : true
    }

    static capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    get editorTitle() {
        if (!this.hasUserInfo) return localizer('Unknown Context')

        if (this.isDraft) {
            return localizer('New hourly request')
        }

        if (this.isMy) {
            return localizer('My') + ' ' + localizer(this.statusDesc) + ' ' + localizer('hourly request')
        }

        if (this.isPendingToMe) {
            return localizer('Hourly request pending to me')
        }

        return hrequestModel.capitalizeFirstLetter(localizer(this.statusDesc)) + ' ' + localizer('request')
    }






    get date() {
        if (this.#date === null) {
            this.#date = this.data.ts_date === null ? false : dayjs(this.data.ts_date)
        }
        return this.#date
    }

    get dateDisp() {
        return this.date.format('MMM D')
    }

    get fromTimeDisp() {
        return hrequestModel.timeDisp(this.data.from_time)
        //return this.data.from_time[0] + this.data.from_time[1] + ':' + this.data.from_time[2] + this.data.from_time[3]
    }

    get tillTimeDisp() {
        return hrequestModel.timeDisp(this.data.till_time)
        //return this.data.till_time[0] + this.data.till_time[1] + ':' + this.data.till_time[2] + this.data.till_time[3]
    }


    static timeDisp(time) {
        if (time instanceof Object) {
            let HH = time.hours.toString()
            if (HH.length == 1) {
                HH = '0'+HH
            }
            let mm = time.minutes.toString()
            if (mm.length == 1) {
                mm = '0'+mm
            }
            
            return HH+':'+mm
        }

        time = time.toString().replace(':', '')
        return time[0]+time[1]+':'+time[2]+time[3]
    }

    static timeDigit(time) {
        return hrequestModel.timeDisp(time).replace(':','')
    }

    static timeObj(time) {
        time = hrequestModel.timeDisp(time)

        return {
            hours: parseInt(time[0]+time[1]),
            minutes: parseInt(time[3]+time[4])
        }
    }



    get timeRange() {
        return this.fromTimeDisp + ' - ' + this.tillTimeDisp
    }

    get durationMs() {
        if (this.#durationMs === null) {
            this.#durationMs = dayjs(this.date.format('YYYY-MM-DD') + ' ' + this.tillTimeDisp).valueOf() - dayjs(this.date.format('YYYY-MM-DD') + ' ' + this.fromTimeDisp).valueOf()
        }

        return this.#durationMs
    }


    get durationHhhmm() {
        return dayjs.duration(this.durationMs).format('HH:mm')
    }


    get typeDesc() {
        return hrequestModel.typeToDesc(this.data.type)
    }

    static typeToDesc(type) {
        type = parseInt(type)

        switch (type) {
            case 1: return localizer('private leave')
            case 2: return localizer('give back for leave/lateness')
            case 3: return localizer('overtime')
            case 4: return localizer('1:1 get back for overtime')
            case 5: return localizer('1:1.5 get back for overtime')
            case 6: return localizer('paid absence')
            case 7: return localizer('unpaid absence')
            case 8: return localizer('check in/out missing')
            case 9: return localizer('child care')
            case 10: return localizer('lateness')
            case 11: return localizer('hourly business leave')
            case 12: return localizer('fractional vacation')
            default: return localizer('other event')
        }
    }


    static backTypes = {
        1: [2],
        10: [2],
        3: [4,5]
    }



    get icon() {
        return hrequestModel.typeToIcon(this.data.type)
    }

    static typeToIcon(type) {
        type = parseInt(type)

        switch (type) {
            case 1: return 'logout'
            case 2: return 'swap-horizontal'
            case 3: return 'briefcase'
            case 4: return 'clipboard-clock-outline'
            case 5: return 'clipboard-clock-outline'
            case 6: return 'currency-usd'
            case 7: return 'check-circle-outline'
            case 8: return 'fingerprint'
            case 9: return 'baby-carriage'
            case 10: return 'timer-outline'
            case 11: return 'taxi'
            case 12: return 'view-day-outline'
            default: return 'help-circle-outline'
        }
    }


    get statusDesc() {
        switch (this.data.confirmed) {
            case 0: return 'pending'
            case 1: return 'cancelled'
            case 2: return 'rejected'
            case 3: return 'confirmed'
        }
    }

    get localizedStatusDesc() {
        switch (this.data.type) {
            case 1: return localizer('(rn)'+this.statusDesc)
            case 2: return localizer('(rn)'+this.statusDesc)
            case 3: return localizer('(rn)'+this.statusDesc)
            case 4: return localizer(this.statusDesc)
            case 5: return localizer(this.statusDesc)
            case 6: return localizer('(rż)'+this.statusDesc)
            case 7: return localizer('(rż)'+this.statusDesc)
            case 8: return localizer(this.statusDesc)
            case 9: return localizer('(rż)'+this.statusDesc)
            case 10: return localizer('(rn)'+this.statusDesc)
            case 11: return localizer('(rż)'+this.statusDesc)
            case 12: return localizer(this.statusDesc)
            default: return localizer(this.statusDesc)
        }
    }

    get typeStatusDesc() {
        return this.localizedStatusDesc + ' ' + this.typeDesc
    }


    get opacity() {
        if (this.data.confirmed === 3) return 1
        if (this.data.confirmed === 0) return 0.9
        return 0.7
    }


    get color() {
        switch (this.statusDesc) {
            case 'pending': return 'primary'
            case 'cancelled': return 'tertiary'
            case 'rejected': return 'error'
        }

        return 'onBackground'
    }


    get personDisp() {

        if ((!this.data.hasOwnProperty('employee'))
            || ((this.hasUserInfo) && (this.data.employee_id === this.userInfo.employee.id))
        ) {
            return localizer('me')
        }

        const lname = this.data.employee.person.last_name === 'Rakotondramiandra-Dudzic' ? 'R.-Dudzic' : this.data.employee.person.last_name

        return this.data.employee.person.first_name
            + (this.data.employee.person.middle_name !== null ? ' ' + this.data.employee.person.middle_name + ' ' : ' ')
            + lname
    }


    save(payload, token) {

        payload = {
            ...payload,
            mode: 'request',
            employee_id: this.data.employee_id
        }

        if (!this.isDraft) {
            payload.id = this.data.id
        }

        return this.doSave(payload, token, 'myhr')
    }


    cancel(payload, token) {
        payload.id = this.data.id
        payload.confirmed = 1
        return this.doSave(payload, token, 'myhr')
    }


    updateSupComment(payload, token) {
        payload = {
            ...payload,
            mode: 'super',
            id: this.data.id
        }
        return this.doSave(payload, token, 'teamhr')
    }


    approve(payload, token) {
        payload.id = this.data.id
        return this.doSave(payload, token, 'teamhr')
    }


    doSave(payload, token, method) {
        //console.log(payload)

        return new Promise((resolve, reject) => {

            //console.log(payload)

            axios({
                method: 'post',
                url: 'https://sam-api-proxy.msi.com/.plbpmahr/.'+method+'/',
                headers: {
                    'bpma-api-key': token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(payload)
            })
                .then((resp) => {
                    //console.log('success')

                    unloadDataStores()
                    refetchUserInfo()
                    resolve()
                })
                .catch((error) => {
                    console.log('failure')
                    console.log(error.response.data)

                    let msg

                    if (typeof error.response.data === 'object') {

                        if (typeof error.response.data.error_message === 'string') {
                            msg = localizer(error.response.data.error_message)
                        }
    
                        if (typeof error.response.data.error_message === 'object') {
                            const errorJson = error.response.data.error_message

                            if (errorJson.type) {
                                if (errorJson.type.childcare_full_hours) {
                                    msg = localizer('Child care can only be requested in full hours.')
                                }
        
                                if (errorJson.type.childcare_balance) {
                                    msg = localizer('Insufficient child care balance left, unable to proceed.')
                                }

                                if (errorJson.type.fractional_possible) {
                                    msg = localizer('No fractional vacation left, unable to proceed.')
                                }

                                if (errorJson.type.fractional_has_workschedule) {
                                    msg = localizer('No active workschedule for specified day, unable to proceed.')
                                }

                                if (errorJson.type.fractional_balance) {
                                    msg = localizer('Request time need to match fractional vacation precisely.')
                                }

                            }
    
                            if (errorJson.ts_date) {
                                msg = localizer('Correct date is required.')
                            }
    
                            if (errorJson.ts_back) {
                                msg = localizer('Valid give/get back date is required.')
                            }
    
                            if (errorJson.from_time_str) {
                                msg = localizer('Please provide valid time period.')
                            }      
                            
                            if (errorJson.back_from_time_str) {
                                msg = localizer('Please provide valid give/get back time period.')
                            }  
    
                            //if (errorJson.required_for_overtime) {
                            //    msg = localizer('Comment is required for overtime and give back requests.')
                            //} 
                            
                            if (errorJson.comment) {
                                if (errorJson.comment.required_for_overtime) {
                                    msg = localizer('Comment is required for overtime and give back requests.')
                                } 
                            }

                            if (errorJson.back_comment) {
                                if (errorJson.back_comment.required_for_overtime) {
                                    msg = localizer('Please provide give back comment.')
                                }
                            }
                        }
                    }

                    if (!msg) {
                        //console.log(error.response.data.error_message)
                        msg = localizer('Operation failed for unknown reason.')
                    }

                    reject(msg)
                })
        })
    }

}