//localizer applied
import localizer from './../../localizer'

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { View } from 'react-native'

import TitleBar from '../TitleBar'
import { Appbar, Badge } from 'react-native-paper'  

import TeamBalance from './TeamBalance'
import TeamHRequestsList from './TeamHRequestsList'
import TeamVacationsList from './TeamVacationsList'

const Tab = createMaterialTopTabNavigator()


export default function MyTeam(props) {

    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={localizer("My Team")}>
                <Appbar.Action icon="dots-vertical" onPress={() => { props.navigation.getParent().navigate('Settings') }} />
            </TitleBar>

            <Tab.Navigator>
                <Tab.Screen name={localizer("Vacations")} component={TeamVacationsList} />
                <Tab.Screen name={localizer("Hourly Leaves")} component={TeamHRequestsList} />
                <Tab.Screen name={localizer("Balance")} component={TeamBalance} />
            </Tab.Navigator>

        </View>
    )

}