//localizer applied
import localizer from './../../localizer'

import { useState, useEffect } from 'react'        //useEffect
import { View, FlatList } from 'react-native'
import { Text, TouchableRipple, IconButton, useTheme } from 'react-native-paper'

import { useDispatch, useSelector } from "react-redux"

import { fetchTeamVacation } from '../../redux/teamVacationSlice'

import vacationModel from '../../models/vacationModel'

import Loading from '../Loading'
import HeaderSwitch from '../Components/HeaderSwitch'
import EmptyPlaceholder from '../Components/EmptyPlaceholder'


export default function TeamVacationsList(props) {

    const themeObject = useTheme()

    const [filtered, setFiltered] = useState(true)
    
    const dispatch = useDispatch()
    const teamVacation = useSelector((state) => state.teamVacation)

    useEffect(() => {
        if ((!teamVacation.current) && (!teamVacation.loading)) {
            dispatch(fetchTeamVacation())
        }
    })

    if (teamVacation.current !== true) {
        return <Loading />
    }

    const vacations = teamVacation.data === null ? [] : teamVacation.data.map( vacationNode => new vacationModel(vacationNode) ) .filter( vModel => !filtered || vModel.statusDesc === 'pending' )



    const itemRenderer = (vWrapped) => {

        const vModel = vWrapped.item

        //console.log( vModel )
        //<Text style={{ fontSize: 22, marginBottom: 2 }}>{vModel.dateRangeDisp}</Text>

        return (
            <View style={{ height: 110, width: '100%', justifyContent: 'center', opacity: vModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.getParent().getParent().navigate('VacationEditor', { model: vModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={vModel.icon} /></View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 14, marginBottom: 2, marginTop: -16 }}>{vModel.dateRangeDisp}</Text>
                            <Text style={{ fontSize: 18, marginBottom: 2 }}>{vModel.personDisp}</Text>
                            <Text style={{ fontSize: 14, color: themeObject.colors[vModel.color] }}>{vModel.typeStatusDesc}</Text>
                        </View>

                        {vModel.activeBalance ? (
                            <View style={{ marginLeft: 18, marginRight: 20, width: 50, alignItems: 'center' }}>
                                <Text style={{ fontSize: 22, marginBottom: 2 }}>{vModel.balanceDisp}</Text>
                                <Text style={{ fontSize: 14 }}>{localizer('days')}</Text>
                            </View>) : null}
                    </View>
                </TouchableRipple>
            </View>
        )

    }



    const listRenderer = () => {
        return (
            <FlatList
                data={vacations}
                renderItem={itemRenderer}
                keyExtractor={vModel => vModel.data.id.toString()}
            />
        )
    }

    


    return (
        <View style={{ flex: 1 }}>
            <HeaderSwitch enabled={ filtered } setter={ setFiltered } label={localizer("show pending requests only")} />

            {(vacations.length === 0) ? (<EmptyPlaceholder message={localizer("No requests found")} />) : listRenderer()}

        </View>
    )
}