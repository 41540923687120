//import React from 'react'
import { Provider as PaperProvider } from 'react-native-paper'
import Main from './screens/Main'
import { useColorScheme } from 'react-native'
import appTheme from './theme/appTheme'
import { NavigationContainer } from '@react-navigation/native';
//import { useState } from 'react'


function ThemeProvider() {

    const theme = useColorScheme()
    const colorTheme = ( (theme === 'dark') ? 'dark' : 'light' )
    const themeObject = appTheme( colorTheme )
    
    //console.log(themeObject.colors)

    return (
        <PaperProvider theme={themeObject}>
            <NavigationContainer theme={themeObject}>
                <Main />
            </NavigationContainer>
        </PaperProvider>
    )
}

export default ThemeProvider