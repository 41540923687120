//localizer applied
import localizer from './../../localizer'

import { useEffect } from 'react'
import { View, FlatList } from 'react-native'         //ScrollView
import { Text, useTheme } from 'react-native-paper'     //Divider
import { useDispatch, useSelector } from "react-redux"

import dbtpModel from '../../models/dbtpModel'
import userInfoModel from '../../models/userInfoModel'
import { fetchMyDbtp } from '../../redux/myDbtpSlice'
import Loading from '../Loading'
//import EmptyPlaceholder from '../Components/EmptyPlaceholder'
import DbtpComponent from '../Components/DbtpComponent'
import DbtpRequestsComponent from '../Components/DbtpRequestsComponent'

import RequestsFAB from '../Requests/RequestsFAB'

import dayjs from 'dayjs'



export default function HomeDash(props) {

    const themeObject = useTheme()
    const dispatch = useDispatch()
    const myDbtp = useSelector((state) => state.myDbtp)
    const userInfo = useSelector((state) => state.userInfo)


    useEffect(() => {
        if ((!myDbtp.current) && (!myDbtp.loading)) {
            dispatch(fetchMyDbtp())
        }
    })

    if (myDbtp.current !== true) {
        return <Loading />
    }

    const today = dayjs(dayjs().format('YYYY-MM-DD'))



    const todayCheckinInfo = (uiModel) => {
        if ((uiModel.data.today.in_time === null) && (uiModel.data.today.out_time === null)) {

            if (uiModel.data.today.workschedule.workschedule === 'off') {
                return null
            }

            return (
                <View>
                    <Text style={{ fontSize: 11 }}>{localizer('No check-in on record yet.')}</Text>
                </View>
            )
        }

        if ((uiModel.data.today.in_time !== null) && (uiModel.data.today.out_time === null)) {
            return (
                <View>
                    <Text style={{ fontSize: 11 }}>{localizer('You checked in at:')}</Text>
                    <Text style={{ fontWeight: 'bold' }}>{uiModel.data.today.in_time.substring(11, 16)}</Text>
                    <Text style={{ fontSize: 11 }}>{localizer('have a productive day!')}</Text>
                </View>
            )
        }

        if ((uiModel.data.today.in_time !== null) && (uiModel.data.today.out_time !== null)) {
            return (
                <View>
                    <Text style={{ fontSize: 11 }}>{localizer('You worked:')}</Text>
                    <Text style={{ fontWeight: 'bold' }}>{uiModel.data.today.in_time.substring(11, 16)} - {uiModel.data.today.out_time.substring(11, 16)}</Text>
                    <Text style={{ fontSize: 11 }}>{localizer('have a nice evning!')}</Text>
                </View>
            )
        }


    }




    const todayRenderer = (dModel) => {

        //console.log(userInfo)
        const uiModel = new userInfoModel(userInfo.data)
        //console.log(uiModel)

        //backgroundColor: themeObject.colors.primaryContainer
        //backgroundColor: '#ffeeff' 
        /*
                                <Text style={{ fontSize: 11, paddingTop: 11 }}>Welcome,</Text>
                        <Text style={{ fontWeight: 'bold' }}>{uiModel.personDisp}</Text>
                        <Text style={{ fontSize: 11, paddingTop: 6 }}>today is</Text>
                        <Text style={{ fontWeight: 'bold' }}>{dayjs().format('MMM D')}</Text>
        */

        return (

            <View style={{ marginTop: 15, height: 255 }}>

                <View style={{ flexDirection: 'row', height: 100 }}>

                    <View style={{ height: 90, flex: 3, paddingLeft: 15, marginRight: 15 }}>

                        <Text style={{ fontSize: 11, paddingTop: 11 }}>{localizer('Welcome,')}</Text>
                        <Text style={{ fontWeight: 'bold' }}>{uiModel.personDisp}</Text>

                        <Text style={{ fontSize: 21, paddingTop: 11 }}>{dayjs().format('MMM D')}</Text>


                    </View>

                    {uiModel.data.vacation_balance.childcare_h === 0 ? null : (
                        <View style={{ height: 90, width: 94, padding: 15, borderRadius: 20, marginRight: 12, marginTop: 5, backgroundColor: themeObject.colors.primaryContainer, elevation: 3 }}>
                            <Text style={{ fontSize: 20, textAlign: 'center' }}>{uiModel.data.vacation_balance.childcare_h}</Text>
                            <Text style={{ fontSize: 11, textAlign: 'center' }}>{localizer('child care hours left')}</Text>
                        </View>
                    )}

                    <View style={{ height: 90, width: 94, padding: 15, borderRadius: 20, marginRight: 12, marginTop: 5, backgroundColor: themeObject.colors.primaryContainer, elevation: 3 }}>
                        <Text style={{ fontSize: 20, textAlign: 'center' }}>{uiModel.data.vacation_balance.vacation}</Text>
                        <Text style={{ fontSize: 11, textAlign: 'center' }}>{localizer('vacation days left')}</Text>
                    </View>

                </View>


                <View style={{ flexDirection: 'row', height: 100 }}>

                    <View style={{ height: 90, flex: 3, paddingLeft: 15, marginTop: 10 }}>
                        {
                            uiModel.data.today.workschedule.workschedule === 'off' ? (
                                <Text style={{ fontSize: 11 }}>{localizer('You are off today, enjoy!')}</Text>
                            ) : null
                        }

                        {
                            uiModel.data.today.workschedule.workschedule === 'fixed' ? (
                                <View>
                                    <Text style={{ fontSize: 11 }}>{localizer('Your workschedule for today:')}</Text>
                                    <Text style={{ fontWeight: 'bold' }}>{uiModel.data.today.workschedule.work_starts} - {uiModel.data.today.workschedule.work_ends}</Text>
                                </View>
                            ) : null
                        }

                        {
                            uiModel.data.today.workschedule.workschedule === 'flexible' ? (
                                <View>
                                    <Text style={{ fontSize: 11 }}>{localizer('Your workschedule for today:')}</Text>
                                    <Text style={{ fontWeight: 'bold' }}>{uiModel.data.today.workschedule.workhours}h {localizer('within')} {uiModel.data.today.workschedule.office_starts} - {uiModel.data.today.workschedule.office_ends}</Text>
                                </View>
                            ) : null
                        }

                        {
                            todayCheckinInfo(uiModel)
                        }

                    </View>

                    {uiModel.data.hourly_balance.overtime_dec === 0 ? null : (
                        <View style={{ height: 90, width: 94, padding: 15, borderRadius: 20, marginRight: 12, marginTop: 5, backgroundColor: themeObject.colors.tertiaryContainer, elevation: 3 }}>
                            <Text style={{ fontSize: 20, textAlign: 'center' }}>{uiModel.data.hourly_balance.overtime_dec > 0 ? '+' : null}{uiModel.data.hourly_balance.overtime_hhmm}</Text>
                            <Text style={{ fontSize: 11, textAlign: 'center' }}>{localizer('overtime balance')}</Text>
                        </View>
                    )}

                    {uiModel.data.hourly_balance.leave_dec === 0 ? null : (
                        <View style={{ height: 90, width: 94, padding: 15, borderRadius: 20, marginRight: 12, marginTop: 5, backgroundColor: themeObject.colors.tertiaryContainer, elevation: 3 }}>
                            <Text style={{ fontSize: 20, textAlign: 'center' }}>{uiModel.data.hourly_balance.leave_dec > 0 ? '+' : null}{uiModel.data.hourly_balance.leave_hhmm}</Text>
                            <Text style={{ fontSize: 11, textAlign: 'center' }}>{localizer('private leave balance')}</Text>
                        </View>
                    )}
                </View>

                <View style={{ flexDirection: 'row', height: 40 }}>
                    <View style={{ height: 40, width: 180, paddingTop: 15, paddingLeft: 15 }}>
                        <Text style={{ fontSize: 11 }}>{dModel.requestModels.length === 0 ? localizer('No requests placed for today.') : localizer('Requests for today:')}</Text>
                    </View>

                    <View style={{ flex: 1, marginTop: 27, width: '100%' }}>
                        <DbtpRequestsComponent dbtpModel={dModel} navigation={props.navigation.getParent().getParent()} />
                    </View>

                </View>
            </View>



        )
    }

    const itemRenderer = (dbtp) => {
        const dModel = new dbtpModel(dbtp.item)

        if (dModel.date.valueOf() === today.valueOf()) {
            return todayRenderer(dModel)
        }

        return (
            <DbtpComponent navigation={props.navigation.getParent().getParent()} dbtpModel={dModel} nameToDisplay={dModel.dateDisp} markMissing={false} />
        )
    }

    const listRenderer = () => {
        return (
            <FlatList
                data={myDbtp.data}
                renderItem={itemRenderer}
                keyExtractor={node => node.date + node.employee_code}
                contentContainerStyle={{ paddingBottom: 60 }}
            />
        )
    }



    return (
        <View style={{ flex: 1 }}>
            {((myDbtp.data === null) || (myDbtp.data.length === 0)) ? todayRenderer() : listRenderer()}

            <RequestsFAB navigation={props.navigation} />
        </View>
    )

}