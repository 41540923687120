//localizer applied
import localizer from './../../localizer'

import { useState, useCallback } from 'react'    //useEffect

import { View, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native'
import { Appbar, TextInput, Text, Button, useTheme } from 'react-native-paper'


import { useSelector } from "react-redux"           //useDispatch

//import { DatePickerModal } from 'react-native-paper-dates'
import { DatePickerModal } from '../../../custom_modules/react-native-paper-dates'

import { rnpdLocales } from '../../localizer'

import Toast from 'react-native-root-toast'

import TitleBar from '../TitleBar'
import vacationModel from '../../models/vacationModel'
import SelectComponent from '../Components/SelectComponent'
import SelectLink from '../Components/SelectLink'
//import Loading from '../Loading'

import dayjs from 'dayjs'

export default function VacationEditor(props) {
    const themeObject = useTheme()
    //console.log(props)

    //const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const appConfig = useSelector((state) => state.appConfig)


    const vModel = ((props.route.params instanceof Object) && (props.route.params.hasOwnProperty('model'))) ? props.route.params.model : new vacationModel({}, userInfo.data)
    vModel.insertUserInfo(userInfo.data)

    //console.log(vModel.debug)
    //console.log(vModel.data)


    //type
    const [type, setType] = useState(vModel.data.type)
    const typeOptions = []
    if (vModel.isEditable) {
        vacationModel.editableTypes.forEach(editableType => {
            if ((editableType !== 'unpaidbook') || (userInfo.data.vacation_balance.vacation === 0)) {
                typeOptions.push({ v: editableType, label: vacationModel.typeToDesc(editableType) })
            }
        })
    }


    //deputy_employee_id
    const [deputy_employee_id, setDeputy_employee_id] = useState(vModel.data.deputy_employee_id)
    const deputy_employee_idOptions = [{ v: 0, label: localizer('not specified or default') }]
    userInfo.data.possible_deputy.forEach(possibleDeputyNode => {
        deputy_employee_idOptions.push({
            v: possibleDeputyNode.id,
            label: possibleDeputyNode.person.first_name + (possibleDeputyNode.person.middle_name ? possibleDeputyNode.person.middle_name + ' ' : ' ') + possibleDeputyNode.person.last_name
        })
    })

    //console.log(deputy_employee_id)
    //console.log(deputy_employee_idOptions)

    //comment
    const [comment, setComment] = useState(vModel.data.comment)



    //console.log( vModel.start )
    //console.log( vModel.end )

    //daterange
    const [dateRange, setDateRange] = useState({ startDate: vModel.start ? vModel.start.toDate() : false, endDate: vModel.end ? vModel.end.toDate() : false })
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const onDismiss = useCallback(() => {
        setDatePickerOpen(false)
    }, [setDatePickerOpen])

    const onConfirm = useCallback(
        ({ startDate, endDate }) => {

            if (typeof startDate === 'undefined') {
                startDate = false
            }
            if (typeof endDate === 'undefined') {
                endDate = startDate
            }
            setDatePickerOpen(false)
            setDateRange({ startDate, endDate })
        },
        [setDatePickerOpen, setDateRange]
    )


    const datePickerLabel = () => {
        if ((!dateRange.startDate) || (!dateRange.endDate)) {
            //return 'please select date'
            return null
        }

        const from = dayjs(dateRange.startDate).format('D MMM YYYY')
        const till = dayjs(dateRange.endDate).format('D MMM YYYY')

        return from === till ? from : from + ' - ' + till
    }


    const [saving, setSaving] = useState(0)

    const saveAction = () => {

        if (saving !== 0) {
            return
        }

        if (dateRange.startDate === false) {
            Toast.show(localizer('Date range is required') )
            return
        }

        setSaving(1)

        const payload = {
            type,
            deputy_employee_id,
            comment,
            ts_start: dayjs(dateRange.startDate).format('YYYY-MM-DD'),
            ts_end: dayjs(dateRange.endDate).format('YYYY-MM-DD')
        }

        vModel.save(payload, userInfo.token).then(() => {
            if (vModel.isDraft) {
                Toast.show(localizer('Request placed'))
            } else {
                Toast.show(localizer('Request updated'))
            }
            props.navigation.goBack()
        }).catch((error) => {
            setSaving(0)

            if (!error) {
                error = localizer('Operation failed for unknown reason.')
            }

            Toast.show(error)
        })

    }

    const cancelAction = () => {
        
        if (saving === 0) {
            setSaving(4)

            vModel.cancel({ comment }, userInfo.token).then(() => {
                Toast.show(localizer('Request cancelled'))
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })

        }
    }


    const approveAction = (approved) => {

        if (saving === 0) {

            const payload = {}

            if (approved) {
                setSaving(3)
                payload.confirmed = 3
            } else {
                setSaving(2)
                payload.confirmed = 2
            }

            vModel.approve(payload, userInfo.token).then(() => {
                if (approved) {
                    Toast.show(localizer('Request approved'))
                } else {
                    Toast.show(localizer('Request rejected'))
                }
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })
        }
    }



    //if (saving) {
    //    return (<Loading />)
    //}

    return (
        <TouchableWithoutFeedback onPress={() => { (Platform.OS === 'web') ? null : Keyboard.dismiss() }}>
            <View style={{ flex: 1 }}>
                <TitleBar title={vModel.editorTitle}>
                    <Appbar.Action icon="arrow-left" onPress={() => { props.navigation.goBack() }} />
                </TitleBar>

                <View style={{ flex: 1 }}>
                    {
                        //type
                        vModel.isEditable ?
                            (<SelectComponent value={type} options={typeOptions} setter={(v) => setType(v)} icon={vacationModel.typeToIcon(type)} label={localizer("Request type")} emphasizeValue={true}  ></SelectComponent>)
                            :
                            (<SelectLink icon={vacationModel.typeToIcon(type)} label={localizer("Request type")} sublabel={vacationModel.typeToDesc(type)} emphasizeValue={true} disabled={true} ></SelectLink>)
                    }

                    {
                        //deputy
                        vacationModel.deputyPossibleTypes.includes(type) ?
                            vModel.isEditable ?
                                (<SelectComponent icon="account" value={deputy_employee_id} options={deputy_employee_idOptions} setter={(v) => setDeputy_employee_id(v)} label={localizer("Deputy employee")} emphasizeValue={true}  ></SelectComponent>)
                                :
                                vModel.data.deputy_employee_fname ?
                                    (<SelectLink icon="account" label={localizer("Deputy employee")} sublabel={vModel.data.deputy_employee_fname} emphasizeValue={true} disabled={true} ></SelectLink>)
                                    :
                                    (<SelectLink icon="account" label={localizer("Deputy employee")} sublabel={localizer("not specified or default")} emphasizeValue={true} disabled={true} ></SelectLink>)
                            :
                            null
                    }

                    {
                        //date range
                        vModel.isBalanceEvent ? null : (
                            <TextInput mode="outlined" label={localizer("Date range")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }}
                                value={datePickerLabel()}
                                disabled={vModel.isEditable ? false : true}
                                onFocus={vModel.isEditable ? () => setDatePickerOpen(true) : null}
                            />
                        )
                    }

                    <DatePickerModal
                        locale={rnpdLocales[appConfig.data.locale].name}
                        mode="range"
                        visible={datePickerOpen}
                        onDismiss={onDismiss}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onConfirm={onConfirm}
                        label={localizer("Select period")}
                        saveLabel={localizer("Apply")}
                        animationType="none"
                        uppercase={false}
                        disableStatusBar={true}
                        disableStatusBarPadding={true}
                        startLabel={localizer("from")}
                        endLabel={localizer("till")}
                        validRange={{ startDate: dayjs(dayjs().format('YYYY') + '-01-01').toDate(), endDate: dayjs(dayjs().format('YYYY') + '-12-31').toDate() }}
                    />


                    <TextInput mode="outlined" label={localizer("Comment")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }} value={comment} disabled={vModel.isEditable ? false : true} onChangeText={text => setComment(text)} />


                    <View style={{ alignItems: "center", marginTop: 30, width: "100%" }}>
                        {
                            vModel.isEditable ?
                                vModel.isDraft ?
                                    (<Button style={{ width: 200 }} icon="check" loading={saving ? true : false} mode="contained-tonal" onPress={saveAction} >{localizer('Place Request')}</Button>)
                                    :
                                    (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Button
                                                buttonColor={themeObject.colors.error}
                                                textColor={themeObject.colors.onError}
                                                style={{ width: 160, marginRight: 15 }}
                                                icon="close" loading={saving === 4 ? true : false}
                                                mode="contained"
                                                onPress={() => cancelAction(false)} >
                                                {localizer('Cancel')}                                                
                                            </Button>
                                            <Button style={{ width: 160, marginLeft: 15 }} icon="check" loading={saving === 1 ? true : false} mode="contained-tonal" onPress={saveAction} >{localizer('Update')}</Button>
                                        </View>
                                    )

                                :
                                vModel.isPendingToMe ?
                                    (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Button
                                                buttonColor={themeObject.colors.error}
                                                textColor={themeObject.colors.onError}
                                                style={{ width: 160, marginRight: 15 }}
                                                icon="close" loading={saving === 2 ? true : false}
                                                mode="contained"
                                                onPress={() => approveAction(false)} >
                                                {localizer('Reject')}
                                            </Button>
                                            <Button
                                                //buttonColor={themeObject.colors.primary}
                                                //textColor={themeObject.colors.onPrimary}
                                                style={{ width: 160, marginLeft: 15 }}
                                                icon="check" loading={saving === 3 ? true : false}
                                                mode="contained"
                                                onPress={() => approveAction(true)} >
                                                {localizer('Approve')}
                                            </Button>
                                        </View>
                                    )
                                    :
                                    null
                        }
                    </View>




                </View>
            </View>
        </TouchableWithoutFeedback>
    )
}
