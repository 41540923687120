import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
//import dayjs from "dayjs"


const teamVBalanceResultsSorter = (a, b) => {
    return a.employee.person.last_name < b.employee.person.last_name ? -1 : 1
}



const doFetchTeamVBalance = async (authtoken) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.teamvbalance/',
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort( teamVBalanceResultsSorter )
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}

export const fetchTeamVBalance = createAsyncThunk(
    "fetchTeamVBalance",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const teamVBalance = await doFetchTeamVBalance(state.userInfo.token)
        if (teamVBalance.success) {
            return teamVBalance.data
        }

        return thunkAPI.rejectWithValue(teamVBalance.error)
    }
)

export const silentFetchTeamVBalance = createAsyncThunk(
    "silentFetchTeamVBalance",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.teamVBalance.current)) {
            return null
        }

        const teamVBalance = await doFetchTeamVBalance(state.userInfo.token)
        if (teamVBalance.success) {
            return teamVBalance.data
        }

        return thunkAPI.rejectWithValue(teamVBalance.error)
    }
)




const teamVBalanceSlice = createSlice({
    name: "teamVBalance",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null
    },

    reducers: {
        unloadTeamVBalance(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }
    },

    extraReducers: (builder) => {

        builder.addCase(fetchTeamVBalance.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchTeamVBalance.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchTeamVBalance.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(silentFetchTeamVBalance.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchTeamVBalance.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }

        })

        builder.addCase(silentFetchTeamVBalance.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })

    },

})

export const { unloadTeamVBalance } = teamVBalanceSlice.actions

export default teamVBalanceSlice


