//localizer applied
import localizer from './../../localizer'

import { View } from 'react-native'
import { Text } from 'react-native-paper'

export default function EmptyPlaceholder(props) {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 21, opacity: 0.3 }}>{ props.message ? props.message : localizer('Nothing found') }</Text>
        </View>
    )
}