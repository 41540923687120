import store from "./src/redux/store"
import { Provider } from "react-redux";
import ThemeProvider from "./src/ThemeProvider"
import { RootSiblingParent } from 'react-native-root-siblings'


import 'intl'
import 'intl/locale-data/jsonp/en' // or any other locale you need
import 'intl/locale-data/jsonp/pl-PL'

import { LogBox } from 'react-native'

LogBox.ignoreLogs(['Require cycle'])

export default function App() {
  return (
    <RootSiblingParent><Provider store={store}><ThemeProvider /></Provider></RootSiblingParent>
  )
}
