import { View } from 'react-native'
import { Text, IconButton } from 'react-native-paper'


export default function PeriodSelector(props) {


    


    return (
        <View style={{height: 35, marginTop: 6, marginBottom: 3 }}>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                <IconButton icon="chevron-left" mode="contained" style={{ width: 30, height: 30}} onPress={ props.prev } disabled={ props.disPrev === props.name ? true : false } />
                <Text style={{ alignItems: 'center', fontWeight: 'bold', width: 200, textAlign: 'center'}}>{ props.name }</Text>
                <IconButton icon="chevron-right" mode="contained" style={{ width: 30, height: 30}} onPress={ props.next } disabled={ props.disNext === props.name ? true : false } />
            </View>
        </View>
    )
}