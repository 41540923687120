import * as BackgroundFetch from 'expo-background-fetch'
import * as Notifications from 'expo-notifications'
import store from "../redux/store"
import { silentFetchUserInfo } from "../redux/userInfoSlice"
import localizer from '../localizer'

export default async function task() {
    await store.dispatch(silentFetchUserInfo())

    const userInfo = store.getState().userInfo.data
    if (typeof userInfo !== 'object') {
        return BackgroundFetch.BackgroundFetchResult.Failed
    }

    let msg = ''
    const totalCount = userInfo.vacation_pending_count + userInfo.hrequest_pending_count

    if (totalCount === 0) {
        return BackgroundFetch.BackgroundFetchResult.NoData 
    }

    if (userInfo.vacation_pending_count > 0) {
        if (userInfo.vacation_pending_count === 1) {
            msg = localizer('one vacation request')
        } else {
            msg = userInfo.vacation_pending_count + ' ' + localizer('vacation requests')
        }
    }

    if (userInfo.hrequest_pending_count > 0) {
        if (msg !== '') {
            msg+=' and '
        }

        if (userInfo.hrequest_pending_count === 1) {
            msg = localizer('one hourly request')
        } else {
            msg = userInfo.hrequest_pending_count + ' ' + localizer('hourly requests')
        }
    }

    if (totalCount === 1) {
        msg+=' '+localizer('is pending your review.')
    } else {
        msg+=' '+localizer('are pending your review.')
    }

    msg = msg.charAt(0).toUpperCase() + msg.slice(1)

    await Notifications.scheduleNotificationAsync({
        content: {
            body: msg,
            sound: true,
            priority: Notifications.AndroidNotificationPriority.HIGH,
            color: "blue"
        },
        trigger: {
            seconds: 5,
        },
    })

    return BackgroundFetch.BackgroundFetchResult.NewData        //NoData, NewData, Failed
}