import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"


const doFetchCalendar = async (authtoken, from, till, date) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.calendar/?'+ (date === null ? 'from='+from+'&till='+till : 'date='+date ),
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchCalendar = createAsyncThunk(
    "fetchCalendar",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const calendar = await doFetchCalendar(state.userInfo.token, state.calendar.from, state.calendar.till, state.calendar.date )
        if (calendar.success) {
            return calendar.data
        }

        return thunkAPI.rejectWithValue(calendar.error)
    }
)

export const silentFetchCalendar = createAsyncThunk(
    "silentFetchCalendar",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.calendar.current)) {
            return null
        }

        const calendar = await doFetchCalendar(state.userInfo.token, state.calendar.from, state.calendar.till, state.calendar.date )
        if (calendar.success) {
            return calendar.data
        }

        return thunkAPI.rejectWithValue(calendar.error)
    }
)




const calendarSlice = createSlice({
    name: "calendar",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null,
        from: null,
        till: null,
        date: dayjs(dayjs().format('YYYY-MM')).format('YYYY-MM-DD')
    },

    reducers: {
        setCalendarFromTill(state, action) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.date = null
            state.from = action.payload.from
            state.till = action.payload.till
        },
        setCalendarDate(state, action) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.date = action.payload
            state.from = null
            state.till = null
        },
        unloadCalendar(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }

    },

    extraReducers: (builder) => {

        builder.addCase(fetchCalendar.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchCalendar.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchCalendar.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(silentFetchCalendar.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchCalendar.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(silentFetchCalendar.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })

    },

})


export const { setCalendarFromTill, setCalendarDate, unloadCalendar } = calendarSlice.actions

export default calendarSlice
