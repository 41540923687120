//localizer applied
import localizer from './../../localizer'

import { useEffect } from 'react'
import { View, FlatList } from 'react-native'
import { useDispatch, useSelector } from "react-redux"

import dbtpModel from '../../models/dbtpModel'
import { fetchTeamDbtp } from '../../redux/teamDbtpSlice'
import Loading from '../Loading'
import EmptyPlaceholder from '../Components/EmptyPlaceholder'
import DbtpComponent from '../Components/DbtpComponent'


export default function TeamAttendanceInner(props) {
    const dispatch = useDispatch()
    const teamDbtp = useSelector((state) => state.teamDbtp)
    
    useEffect(() => {
        if ((!teamDbtp.current) && (!teamDbtp.loading)) {
            dispatch(fetchTeamDbtp())
        }
    })

    if (!teamDbtp.current) {
        return <Loading />
    }

    const itemRenderer = (dbtp) => {
        const dModel = new dbtpModel(dbtp.item)

        return (
            <DbtpComponent navigation={ props.navigation.getParent().getParent() } dbtpModel={dModel} nameToDisplay={dModel.personDisp} markMissing={true}  />
        )
    }

    const listRenderer = () => {
        return (
            <FlatList
                data={teamDbtp.data}
                renderItem={itemRenderer}
                keyExtractor={node => node.date + node.employee_code}
            />
        )
    }


    return (
        <View style={{ flex: 1 }}>
            {((teamDbtp.data === null) || (teamDbtp.data.length === 0)) ? (<EmptyPlaceholder message={localizer("No entries found")} />) : listRenderer()}
        </View>
    )
}