//localizer applied
import localizer from './../../localizer'

import { useEffect, useState } from 'react'        //useState, useEffect
import { ScrollView, View } from 'react-native' //FlatList
import { DataTable, IconButton, Searchbar } from 'react-native-paper'       //Text, TouchableRipple, IconButton, useTheme 

import { useDispatch, useSelector } from "react-redux"
import { fetchTeamVBalance } from '../../redux/teamVBalanceSlice'

import vbModel from '../../models/vbModel'

import Loading from '../Loading'
//import HeaderSwitch from '../Components/HeaderSwitch'
import EmptyPlaceholder from '../Components/EmptyPlaceholder'


export default function TeamBalance(props) {

    //const themeObject = useTheme()

    const dispatch = useDispatch()
    const teamVBalance = useSelector((state) => state.teamVBalance)
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if ((!teamVBalance.current) && (!teamVBalance.loading)) {
            dispatch(fetchTeamVBalance())
        }
    })

    if (teamVBalance.current !== true) {
        return <Loading />
    }

    if ((teamVBalance.data === null) || (teamVBalance.data.length === 0)) {
        return (
            <EmptyPlaceholder message={localizer("No balance info found")} />
        )
    }

    const bdata = searchQuery === '' ? teamVBalance.data.map(node => new vbModel(node)) : teamVBalance.data.map(node => new vbModel(node)).filter(vbm => vbm.personDisp.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)





    return (
        <ScrollView>
            {teamVBalance.data.length > 9 ? (
                <Searchbar value={searchQuery} onChangeText={query => setSearchQuery(query)} />
            ) : null}

            <DataTable>
                <DataTable.Header>
                    <DataTable.Title style={{ flex: 9 }}>{localizer('Employee Name')}</DataTable.Title>
                    <DataTable.Title style={{ flex: 3 }} numeric>{localizer('Vacation')}</DataTable.Title>
                    <DataTable.Title style={{ flex: 3 }} numeric>{localizer('Child Care')}</DataTable.Title>
                    <DataTable.Title style={{ flex: 4 }} numeric>{localizer('Overtimes')}</DataTable.Title>
                    <DataTable.Title style={{ flex: 4 }} numeric>{localizer('Leaves')}</DataTable.Title>
                </DataTable.Header>

                {
                    bdata.map(vbm => {
                        //const vbm = new vbModel(node)

                        return (
                            <DataTable.Row key={vbm.personDisp}>
                                <DataTable.Cell style={{ flex: 9 }} textStyle={{ fontSize: 11 }} >{vbm.personLnDisp}</DataTable.Cell>
                                <DataTable.Cell style={{ flex: 3 }} textStyle={{ fontSize: 13 }} numeric>{vbm.data.vacation_balance.vacation}</DataTable.Cell>
                                <DataTable.Cell style={{ flex: 3, opacity: vbm.data.vacation_balance.childcare_h === 0 ? 0.3 : 1 }} textStyle={{ fontSize: 13 }} numeric>{vbm.data.vacation_balance.childcare_h}h</DataTable.Cell>
                                <DataTable.Cell style={{ flex: 4, opacity: vbm.data.hourly_balance.overtime_hhmm === '0:00' ? 0.3 : 1 }} textStyle={{ fontSize: 13 }} numeric>{vbm.data.hourly_balance.overtime_hhmm}h</DataTable.Cell>
                                <DataTable.Cell style={{ flex: 4, opacity: vbm.data.hourly_balance.leave_hhmm === '0:00' ? 0.3 : 1 }} textStyle={{ fontSize: 13 }} numeric>{vbm.data.hourly_balance.leave_hhmm}h</DataTable.Cell>
                            </DataTable.Row>
                        )
                    })

                }
            </DataTable>
        </ScrollView>
    )

}