import { View } from 'react-native'
//import { Text} from 'react-native-paper'

import { useDispatch, useSelector } from "react-redux"
import { previousDay, nextDay } from '../../redux/teamDbtpSlice'
import PeriodSelector from '../Components/PeriodSelector'
import TeamAttendanceInner from './TeamAttendanceInner'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)


export default function TeamAttendance(props) {

    const dispatch = useDispatch()
    const teamDbtp = useSelector((state) => state.teamDbtp)

    return (
        <View style={{ flex: 1 }}>
            <PeriodSelector name={ dayjs(teamDbtp.from).format('Do MMMM YYYY') } prev={ () => dispatch(previousDay()) } next={ () => dispatch(nextDay()) } disNext={ dayjs().format('Do MMMM YYYY') }  />

            <TeamAttendanceInner navigation={ props.navigation } />
        </View>
    )
}