//localizer applied
import localizer from './../../localizer'

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { View } from 'react-native'

import TitleBar from '../TitleBar'
import { Appbar } from 'react-native-paper'

import HomeDash from './HomeDash'

import TeamAttendance from './TeamAttendance'


const Tab = createMaterialTopTabNavigator()


export default function Home(props) {

    //console.log(props.navigation.getParent())

    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={localizer("Home")}>
                <Appbar.Action icon="dots-vertical" onPress={() => { props.navigation.getParent().navigate('Settings') }} />
            </TitleBar>

            <Tab.Navigator>
                <Tab.Screen name={localizer("My Dashboard")} component={HomeDash} />
                <Tab.Screen name={localizer("Team Attendance")} component={TeamAttendance} />
            </Tab.Navigator>
        </View>
    )

}