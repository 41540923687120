import dayjs from "dayjs"
import hrequestModel from "./hrequestModel"
import vacationModel from "./vacationModel"

import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

//import advancedFormat from 'dayjs/plugin/advancedFormat'
//dayjs.extend(advancedFormat)

export default class dbtpModel {

    /*
    "class": "dbtph",
    "date": "2022-12-30",
    "employee": Object {
      "active": "1",
      "class": "employee",
      "department": "ICT",
      "hqid": 50017411,
      "id": 50,
      "localid": "R0725",
      "person": Object {
        "address": Object {
          "address": null,
          "city": null,
          "class": "address",
          "country": Object {
            "class": "country",
            "id": 3,
            "name": "Poland",
            "short_name": "PL",
          },
          "country_id": "3",
          "geolock": null,
          "id": 3868,
          "lat": null,
          "lng": null,
          "postalcode": null,
          "region": null,
          "rel_id": 2795,
          "rel_table": "person",
        },
        "birthday": null,
        "class": "person",
        "first_name": "Kacper",
        "id": 2795,
        "job_title": null,
        "language": Object {
          "class": "language",
          "id": 2,
          "local_name": "Polski",
          "name": "Polish",
          "short_name": "pl",
        },
        "last_name": "Skórzyński",
        "middle_name": null,
        "user_id": 122,
      },
      "person_id": 0,
      "supervisor_id": 226,
      "ts_contract_end": null,
      "ts_contract_start": "2020-01-02 07:55:30",
      "user_id": 122,
    },
    "employee_code": "R0725",
    "intime": "07:58:50",
    "intime_flat": "08:00:00",
    "late": false,
    "outtime": null,
    "outtime_flat": null,
    "overfime": null,
    "overfime_ws": null,
    "requests": Array [],
  },
}

    */


    #date = null
    #durationMs = null

    constructor(data) {
        this.data = data
    }


    get date() {
        if (this.#date === null) {
            this.#date = dayjs(this.data.date)
        }
        return this.#date
    }

    get dateDisp() {
        return this.date.format('MMM D')
    }

    get personDisp() {

      const lname = this.data.employee.person.last_name === 'Rakotondramiandra-Dudzic' ? 'R.-Dudzic' : this.data.employee.person.last_name

      return this.data.employee.person.first_name
        +( this.data.employee.person.middle_name !== null ? ' '+this.data.employee.person.middle_name+' ' : ' '  )
        +lname
    }

    get intimeToUse() {
      return this.data.hasOwnProperty('intime') ? this.data.intime : this.data.intime_flat
    }

    get outtimeToUse() {
      return this.data.hasOwnProperty('outtime') ? this.data.outtime : this.data.outtime_flat
    }

    get intimeDisp() {
      if (this.intimeToUse !== null) {
        return this.intimeToUse.substring(0,5)
      }

      return 'NA'
    }

    get outtimeDisp() {
      if (this.outtimeToUse !== null) {
        return this.outtimeToUse.substring(0,5)
      }

      return 'NA'
    }

    get requestModels() {
      const requestModels = []
      this.data.requests.forEach( request => requestModels.push( request.class === 'vacation' ? new vacationModel(request) : new hrequestModel(request)) )
      return requestModels
    }


    get overtimeToUse() {
      return this.data.hasOwnProperty('overfime') ? this.data.overfime : this.data.overfime_ws
    }

    get overtimeDisp() {
      if (!this.overtimeToUse) {
        return null
      }

      return (this.overtimeToUse[0] === '-') ? this.overtimeToUse.substring(0,6) : '+'+this.overtimeToUse.substring(0,5)
    }


    get overttimeColor() {
      if ((this.overtimeDisp !== null) && (this.overtimeDisp[0] === '-')) {
        return 'error'
      }
      return 'primary'
    }






    /*
    get durationMs() {
        if (this.#durationMs === null) {
            this.#durationMs = dayjs( this.date.format('YYYY-MM-DD')+' '+this.tillTimeDisp ).valueOf() - dayjs( this.date.format('YYYY-MM-DD')+' '+this.fromTimeDisp ).valueOf()
        }
                
        return this.#durationMs
    }


    get durationHhhmm() {
        return dayjs.duration(this.durationMs).format('HH:mm')
    }
    */


}