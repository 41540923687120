//localizer applied
import localizer from '../localizer'

import { View } from 'react-native'
import { useTheme, Text, Appbar, Button, IconButton } from 'react-native-paper'
import Constants from 'expo-constants'

import { useDispatch } from "react-redux"       //useSelector

import { logOut } from '../redux/userInfoSlice'
import { unloadDataStores } from './../dispatchers'
import Toast from 'react-native-root-toast'

import TitleBar from './TitleBar'



export default function DisabledApp(props) {
    const themeObject = useTheme()
    const dispatch = useDispatch()

    const logOutAction = () => {
        dispatch(logOut()).then(() => {
            unloadDataStores()
            Toast.show('Successfully logged out')
        })
    }

    return (
        <View style={{ flex: 1, backgroundColor: themeObject.colors.background }}>
            <TitleBar title={localizer("Update required")}>
                <Appbar.Action icon="dots-vertical" disabled />
            </TitleBar>

            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginBottom: 300 }}>
                <Text style={{ fontSize: 24 }}>{localizer("Update required")}</Text>
                <IconButton icon="cellphone-arrow-down" size={96} iconColor={themeObject.colors.error} />
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 11, paddingTop: 4 }}>{localizer("You are using version")} </Text>
                    <Text style={{ fontSize: 11, paddingTop: 4, fontWeight: 'bold' }}>{Constants.manifest.version}</Text>
                    <Text style={{ fontSize: 11, paddingTop: 4 }}>.</Text>
                </View>
                <Text style={{ fontSize: 11, paddingTop: 10 }}>{localizer("SmartBPMA administrators marked this build")}</Text>
                <Text style={{ fontSize: 11, paddingTop: 2 }}>{localizer("as no longer compatibilie with system servers.")}</Text>
                <Text style={{ fontSize: 11, paddingTop: 2 }}>{localizer("Please access SmartBPMA via web and follow")}</Text>
                <Text style={{ fontSize: 11, paddingTop: 2 }}>{localizer("the instructions how to update.")}</Text>

                <Button style={{ marginTop: 40 }} mode="contained" onPress={logOutAction}>{localizer("Sign Out")}</Button>

            </View>
        </View>
    )
}
