//localizer applied
import localizer from './../../localizer'

import { View, ScrollView } from 'react-native'
import { Appbar, Text, TouchableRipple, useTheme, IconButton } from 'react-native-paper'
import TitleBar from './../TitleBar'

import { useSelector } from "react-redux"       //useDispatch

import calendarModel from '../../models/calendarModel'


import EmptyPlaceholder from '../Components/EmptyPlaceholder'
import dayjs from 'dayjs'



export default function CalendarDay(props) {
    const themeObject = useTheme()
    const date = dayjs(props.route.params.timestamp)

    const calendar = useSelector((state) => state.calendar)
    const userInfo = useSelector((state) => state.userInfo)


    //const calModel = new calendarModel(calendar.data, userInfo.data, date, date)
    const events = new calendarModel(calendar.data, userInfo.data, date, date).getEventsByDay(date.valueOf())

    const empty = ((events.myHr.length === 0) && (events.teamHr.length === 0) && (events.myVacation.length === 0) && (events.teamVacation.length === 0)) ? true : false


    //console.log(props)



    const hrRenderer = (hrModel) => {

        return (
            <View key={'hrequest'+hrModel.data.id } style={{ height: 110, width: '100%', justifyContent: 'center', opacity: hrModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.navigate('HRequestEditor', { model: hrModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={hrModel.icon} /></View>
                        <View style={{ flex: 1 }}>

                            <View style={{ flexDirection: 'row', marginTop: -16 }}>
                                <Text style={{ fontSize: 14, marginBottom: 2, flex: 1 }}>{hrModel.dateDisp}</Text>
                                <Text style={{ fontSize: 14, width: 145, textAlign: 'right', marginBottom: 2, marginRight: 40, opacity: 0.65 }}>{hrModel.timeRange}</Text>
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 18, marginBottom: 2 }}>{hrModel.personDisp}</Text>
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, color: themeObject.colors[hrModel.color], flex: 1 }}>{hrModel.typeStatusDesc}</Text>
                                <Text style={{ fontSize: 14, width: 50, textAlign: 'right', marginBottom: 2, marginRight: 40 }}>{hrModel.durationHhhmm}h</Text>
                            </View>
                        </View>
                    </View>
                </TouchableRipple>
            </View>
        )
    }



    const vacationRenderer = (vModel) => {

        return (
            <View key={'vacation'+vModel.data.id } style={{ height: 110, width: '100%', justifyContent: 'center', opacity: vModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.navigate('VacationEditor', { model: vModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={vModel.icon} /></View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 14, marginBottom: 2, marginTop: -16 }}>{vModel.dateRangeDisp}</Text>
                            <Text style={{ fontSize: 18, marginBottom: 2 }}>{vModel.personDisp}</Text>
                            <Text style={{ fontSize: 14, color: themeObject.colors[vModel.color] }}>{vModel.typeStatusDesc}</Text>
                        </View>

                        {vModel.activeBalance ? (
                            <View style={{ marginLeft: 18, marginRight: 20, width: 50, alignItems: 'center' }}>
                                <Text style={{ fontSize: 22, marginBottom: 2 }}>{vModel.balanceDisp}</Text>
                                <Text style={{ fontSize: 14 }}>{localizer('days')}</Text>
                            </View>) : null}
                    </View>
                </TouchableRipple>
            </View>
        )

    }



    const renderItems = () => {

        return(
        <ScrollView>
            {
            events.myHr.map( hrModel => hrRenderer(hrModel) )
            }
            {
            events.teamHr.map( hrModel => hrRenderer(hrModel) )
            }
            {
            events.myVacation.map( hrModel => vacationRenderer(hrModel) )
            }
            {
            events.teamVacation.map( hrModel => vacationRenderer(hrModel) )
            }



        </ScrollView>
        )
    }


    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={date.format('DD MMM YYYY')}>
                <Appbar.Action icon="arrow-left" onPress={() => { props.navigation.goBack() }} />
            </TitleBar>
            <View style={{ height: 35, marginTop: 6, marginBottom: 3 }}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <Text style={{ alignItems: 'center', fontWeight: 'bold', width: 500, textAlign: 'center' }}>
                        {
                            date.format('dddd')
                        }
                        {
                            events.isHoliday ? ', '+localizer(events.holidayName) : null
                        }
                    </Text>
                </View>
            </View>
            {
                empty ? <EmptyPlaceholder message={localizer("No items to show")} /> : renderItems()
            }
        </View >
    )
}