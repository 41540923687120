import { View } from 'react-native'
import { Text, TouchableRipple, IconButton } from 'react-native-paper'

export default function SelectLink(props) {

    const labelSize = props.emphasizeValue === true ? 14 : 22
    const valueSize = props.emphasizeValue === true ? 22 : 14

    return(
    <View style={{ height: 110, width: '100%', justifyContent: 'center', opacity: props.disabled === true ? 0.8 : 1 }}>
        { props.children }
        <TouchableRipple style={{ height: 110, alignItems: 'center' }} onPress={ props.action } >
            <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={ props.icon } /></View>
                <View>
                    <Text style={{ fontSize: labelSize, marginBottom: 2 }}>{ props.label }</Text>
                    { props.sublabel ? (<Text style={{ fontSize: valueSize }}>{ props.sublabel }</Text>) : null }
                </View>
            </View>
        </TouchableRipple>
    </View>
    )
}