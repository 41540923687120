import * as BackgroundFetch from 'expo-background-fetch'
import * as TaskManager from 'expo-task-manager'
import task from './task'
//import * as Notifications from 'expo-notifications'


const BACKGROUND_FETCH_TASK = 'background-fetch'



TaskManager.defineTask(BACKGROUND_FETCH_TASK, async () => {
    //console.log('Background task...')
    return await task()
})


export const registerBackgroundFetchAsync = async () => {
    //await Notifications.requestPermissionsAsync()
    const registered = await TaskManager.isTaskRegisteredAsync(BACKGROUND_FETCH_TASK)

    if (!registered) {
        console.log('registering background task')

        return BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
            minimumInterval: 60 * 45, //60 * 45, // X minutes
            stopOnTerminate: false, // android only,
            startOnBoot: true, // android only
        })
    }

    console.log('background task is registered')
    return null    
}


export const unregisterBackgroundFetchAsync = async () => {
    const registered = await TaskManager.isTaskRegisteredAsync(BACKGROUND_FETCH_TASK)

    if (registered) {
        //console.log('unregistering background task')
        return BackgroundFetch.unregisterTaskAsync(BACKGROUND_FETCH_TASK)
    }
    
    //console.log('background task is not registered')
    return null    
}










