//localizer applied
import localizer from './../../localizer'

import { useEffect } from 'react'
import { View, FlatList } from 'react-native'
import { Text, TouchableRipple, IconButton, useTheme } from 'react-native-paper'


import Loading from '../Loading'
import { useDispatch, useSelector } from "react-redux"

import { fetchMyVacation } from '../../redux/myVacationSlice'

import RequestsFAB from './RequestsFAB'
import vacationModel from '../../models/vacationModel'

import EmptyPlaceholder from '../Components/EmptyPlaceholder'


export default function VacationsList(props) {

    const themeObject = useTheme()

    const dispatch = useDispatch()
    const myVacation = useSelector((state) => state.myVacation)

    useEffect(() => {
        if ((!myVacation.current) && (!myVacation.loading)) {
            dispatch(fetchMyVacation())
        }
    })

    if (!myVacation.current) {
        return (
            <Loading />
        )
    }

    const itemRenderer = (vacation) => {
        //console.log(vacation)

        const vModel = new vacationModel(vacation.item)
        //console.log(vModel)



        return (
            <View style={{ height: 110, width: '100%', justifyContent: 'center', opacity: vModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.getParent().getParent().navigate('VacationEditor', { model: vModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={vModel.icon} /></View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 22, marginBottom: 2 }}>{vModel.dateRangeDisp}</Text>
                            <Text style={{ fontSize: 14, color: themeObject.colors[vModel.color] }}>{vModel.typeStatusDesc}</Text>
                        </View>

                        {vModel.activeBalance ? (
                            <View style={{ marginLeft: 18, marginRight: 20, width: 50, alignItems: 'center' }}>
                                <Text style={{ fontSize: 22, marginBottom: 2 }}>{vModel.balanceDisp}</Text>
                                <Text style={{ fontSize: 14 }}>{localizer('days')}</Text>
                            </View>) : null}
                    </View>
                </TouchableRipple>
            </View>
        )

    }

    const listRenderer = () => {
        return (
            <FlatList
                data={myVacation.data}
                renderItem={itemRenderer}
                keyExtractor={node => node.id.toString()}

                contentContainerStyle={{ paddingBottom: 60 }}
            />
        )
    }


    return (
        <View style={{ flex: 1 }}>

            {((myVacation.data === null) || (myVacation.data.length === 0)) ? (<EmptyPlaceholder message={localizer("No requests found")} />) : listRenderer()}

            <RequestsFAB navigation={props.navigation} />
        </View>
    )
}