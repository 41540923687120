import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"


const teamDbtpResultsSorter = (a, b) => {

    if (a.date === b.date) {
        return a.employee.person.last_name < b.employee.person.last_name ? -1 : 1
    } 
    return a.date < b.date ? 1 : -1
}



const doFetchTeamDbtp = async (authtoken, from, till) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.teamdbtp/?from='+from+'&till='+till,
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort( teamDbtpResultsSorter )
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchTeamDbtp = createAsyncThunk(
    "fetchTeamDbtp",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        //console.log(state.teamDbtp.from, state.teamDbtp.till)

        const teamDbtp = await doFetchTeamDbtp(state.userInfo.token, state.teamDbtp.from, state.teamDbtp.till )
        if (teamDbtp.success) {
            return teamDbtp.data
        }

        return thunkAPI.rejectWithValue(teamDbtp.error)
    }
)


export const silentFetchTeamDbtp = createAsyncThunk(
    "silentFetchTeamDbtp",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.teamDbtp.current)) {
            return null
        }

        const teamDbtp = await doFetchTeamDbtp(state.userInfo.token, state.teamDbtp.from, state.teamDbtp.till )
        if (teamDbtp.success) {
            return teamDbtp.data
        }

        return thunkAPI.rejectWithValue(teamDbtp.error)
    }
)







const teamDbtpSlice = createSlice({
    name: "teamDbtp",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null,
        from: dayjs().format('YYYY-MM-DD'),
        till: dayjs().format('YYYY-MM-DD')
    },

    reducers: {
        previousDay(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.from = dayjs(state.from).subtract(1, 'day').format('YYYY-MM-DD')
            state.till = state.from
        },
        nextDay(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.from = dayjs(state.from).add(1, 'day').format('YYYY-MM-DD')
            state.till = state.from
        },
        setCustomRange(state, action) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
            state.from = action.payload.from
            state.till = action.payload.till
        },
        unloadTeamDbtp(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }
        

    },

    extraReducers: (builder) => {

        builder.addCase(fetchTeamDbtp.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchTeamDbtp.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchTeamDbtp.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(silentFetchTeamDbtp.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchTeamDbtp.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(silentFetchTeamDbtp.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })
        
    },

})


export const { previousDay, nextDay, setCustomRange, unloadTeamDbtp } = teamDbtpSlice.actions

export default teamDbtpSlice