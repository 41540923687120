import vacationModel from "./vacationModel"
import hrequestModel from "./hrequestModel"
import userInfoModel from "./userInfoModel"

import dayjs from "dayjs"






export default class calendarModel {

    constructor(data, userInfo, fromDate, tillDate) {
        this.userInfo = userInfo
        this.fromDate = fromDate
        this.tillDate = tillDate

        this.eventsByDay = {}

        data.holiday.forEach(holidayNode => this.addHoliday(holidayNode))
        data.hrequest.forEach(hrequestNode => this.addHr(hrequestNode))
        data.vacation.forEach(vacationNode => this.addVacation(vacationNode))
    }


    __blankVal() {
        return {
            isHoliday: false,
            holidayName: null,
            myVacation: [],
            teamVacation: [],
            myHr: [],
            teamHr: []
        }
    }

    getEventsByDay(ts) {
        if (!this.eventsByDay.hasOwnProperty(ts) ) {
            return this.__blankVal()
        }

        return this.eventsByDay[ts]
    }

    addHoliday(holidayNode) {
        const eventDate = dayjs(holidayNode.ts_date)
        const eventTs = eventDate.valueOf()

        if ((eventTs < this.fromDate.valueOf()) || (eventTs > this.tillDate.valueOf())) {
            return
        }

        if (!this.eventsByDay.hasOwnProperty(eventTs)) {
            this.eventsByDay[eventTs] = this.__blankVal()
        }

        this.eventsByDay[eventTs].isHoliday = true
        this.eventsByDay[eventTs].holidayName = holidayNode.name
    }

    addHr(hrequestNode) {
        const hrModel = new hrequestModel(hrequestNode, this.userInfo)

        const eventDate = hrModel.date
        const eventTs = eventDate.valueOf()

        if ((eventTs < this.fromDate.valueOf()) || (eventTs > this.tillDate.valueOf())) {
            return
        }

        if (!this.eventsByDay.hasOwnProperty(eventTs)) {
            this.eventsByDay[eventTs] = this.__blankVal()
        }

        if (hrequestModel.isMy) {
            this.eventsByDay[eventTs].myHr.push(hrModel)
        } else {
            this.eventsByDay[eventTs].teamHr.push(hrModel)
        }
    }


    addVacation(vacationNode) {
        const vModel = new vacationModel(vacationNode, this.userInfo)

        const eventTillTs = Math.min(vModel.end.valueOf(), this.tillDate.valueOf())
        const fromTs = this.fromDate.valueOf()

        let eventDate = vModel.start
        let eventTs = eventDate.valueOf()

        while (eventTs <= eventTillTs) {
            if (eventTs >= fromTs) {
                if (!this.eventsByDay.hasOwnProperty(eventTs)) {
                    this.eventsByDay[eventTs] = this.__blankVal()
                }

                if (vModel.isMy) {
                    this.eventsByDay[eventTs].myVacation.push(vModel)
                } else {
                    this.eventsByDay[eventTs].teamVacation.push(vModel)
                }
            }

            eventDate = eventDate.add(1, 'day')
            eventTs = eventDate.valueOf()
        }
    }











}