import { useState } from 'react'
import { View } from 'react-native'

import PeriodSelector from '../Components/PeriodSelector'

import { useDispatch, useSelector } from "react-redux"
import { previousMonth, nextMonth } from '../../redux/myHRequestSlice'

import HRequestsListInner from './HRequestsListInner'


import dayjs from 'dayjs'

export default function HRequestsList(props) {

    const dispatch = useDispatch()
    const myHRequest = useSelector((state) => state.myHRequest)

    

    return (
        <View style={{ flex: 1 }}>

            <PeriodSelector name={ dayjs(myHRequest.month).format('MMMM YYYY') } prev={ () => dispatch(previousMonth()) } next={ () => dispatch(nextMonth()) } />
            <HRequestsListInner navigation={props.navigation} />

        </View>
    )
}