import { View } from 'react-native'     //StyleSheet
import { Appbar, useTheme } from 'react-native-paper'       //FAB
import { StatusBar } from 'expo-status-bar'
import Constants from 'expo-constants'

export default function TitleBar(props) {
    
    const themeObject = useTheme()

    //elevated="true"

    return (
        <View>
            <Appbar.Header mode="small" style={{ backgroundColor: themeObject.colors.elevation.level2 }}>
                {props.children}
                <Appbar.Content title={ props.title? props.title : Constants.manifest.name } />
            </Appbar.Header>
            <StatusBar style="auto" />
        </View>
    )


}