//localizer applied
import localizer from '../localizer'

import { View } from 'react-native'
import { ActivityIndicator, useTheme, Text, Appbar } from 'react-native-paper'

import TitleBar from './TitleBar'

export default function Loading(props) {
  const themeObject = useTheme()

  return (
    <View style={{ flex: 1, backgroundColor: themeObject.colors.background }}>
      {
        props.main ? (
          <TitleBar title={props.mainMessage ? props.mainMessage : localizer('Loading...') }>
            <Appbar.Action icon="dots-vertical" disabled />
          </TitleBar>
        ) : null
      }
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator animating={true} size="large" color={themeObject.colors.primary} />
        <Text style={{ marginTop: 10, fontSize: 12, color: themeObject.colors.onBackground }} >{props.message ? props.message : localizer('Loading...')}</Text>
      </View>
    </View>
  )
}
