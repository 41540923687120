import { MD3LightTheme, MD3DarkTheme, adaptNavigationTheme } from 'react-native-paper'
import { DarkTheme as NavigationDarkTheme, DefaultTheme as NavigationLightTheme } from '@react-navigation/native'
import merge from 'deepmerge'

import { darkRedColors } from './colorThemes'
import { lightRedColors } from './colorThemes'

const appTheme = (colorTheme) => {

    //const customDarkColors = darkCyanColors.colors
    //const customLightColors = lightCyanColors.colors
    const customDarkColors = {}
    const customLightColors = {}

    const { LightTheme, DarkTheme } = adaptNavigationTheme({
        reactNavigationDark: NavigationDarkTheme,
        materialDark: { ...MD3DarkTheme,
            colors: {
                ...MD3DarkTheme.colors,
                ...customDarkColors
            }
           },

        reactNavigationLight: NavigationLightTheme,
        materialLight: { ...MD3LightTheme,
            colors: {
                ...MD3LightTheme.colors,
                ...customLightColors
            }
        },
      })
   
    const darkThemeToUse = merge(MD3DarkTheme, DarkTheme)
    const lightThemeToUse = merge(MD3LightTheme, LightTheme)

    return (colorTheme === 'dark') ?
    {
        ...darkThemeToUse,
        mode: 'exact',
        colors: { 
            ...darkThemeToUse.colors,
            ...customDarkColors
        }
    }
    : {
        ...lightThemeToUse,
        colors: {
            ...lightThemeToUse.colors,
            ...customLightColors
        }
    }
}

export default appTheme