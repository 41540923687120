import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
//import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
//import { Platform } from 'react-native'

//import { registerBackgroundFetchAsync, unregisterBackgroundFetchAsync } from "../tasks/taskman"


export const initializeAppConfig = createAsyncThunk(
    "initializeAppConfig",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState().appConfig

        if (state.initialized) {
            return state.data
        }

        try {
            const appConfigString = await AsyncStorage.getItem('appConfig')
            return (appConfigString === null) ? { } : JSON.parse(appConfigString)

        } catch (error) {
            return thunkAPI.rejectWithValue('failed to initialize')
        }
    }
)


export const setLocale = createAsyncThunk(
    "setLocale",
    async (localeToSet, thunkAPI) => {

        const state = thunkAPI.getState().appConfig

        try {
            await AsyncStorage.setItem('appConfig', JSON.stringify({ ...state.data, locale: localeToSet }))
            return localeToSet

        } catch (error) {
            return thunkAPI.rejectWithValue('failed to initialize')
        }
    }
)


export const setNotifications = createAsyncThunk(
    "setNotifications",
    async (notificationsToSet, thunkAPI) => {

        const state = thunkAPI.getState().appConfig

        /*
        if (notificationsToSet) {
            registerBackgroundFetchAsync()
        } else {
            unregisterBackgroundFetchAsync()
        }
        */

        try {
            await AsyncStorage.setItem('appConfig', JSON.stringify({ ...state.data, notifications: notificationsToSet }))
            return notificationsToSet

        } catch (error) {
            return thunkAPI.rejectWithValue('failed to initialize')
        }
    }
)




const appConfigSlice = createSlice({
    name: "appConfig",

    initialState: {
        initialized: false,
        loading: false,
        error: false,
        data: {
            locale: 'pl',
            notifications: false,
        }
    },

    reducers: {
        //// -non-thunk reducers
        //increment: (state) => {
        //    state.count += 1;
        //},
    },

    extraReducers: (builder) => {

        //initializeAppConfig
        builder.addCase(initializeAppConfig.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(initializeAppConfig.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.initialized = true
            state.data = { ...state.data, ...action.payload }

            for (const [k, v] of Object.entries(state.data)) {
                global[k] = v
            }
        })

        builder.addCase(initializeAppConfig.rejected, (state) => {
            state.loading = false
            state.error = true
        })


        //setLocale
        builder.addCase(setLocale.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(setLocale.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.data.locale = action.payload

            global.locale = state.data.locale
        })

        builder.addCase(setLocale.rejected, (state) => {
            state.loading = false
            state.error = true
            //state.signedIn = false
            //state.token = null
        })

        //setNotifications
        builder.addCase(setNotifications.pending, (state) => {
            //state.loading = true
            state.error = false
        })

        builder.addCase(setNotifications.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.data.notifications = action.payload

            global.notifications = state.data.notifications
        })

        builder.addCase(setNotifications.rejected, (state) => {
            state.loading = false
            state.error = true
            //state.signedIn = false
            //state.token = null
        })

    },

})

export default appConfigSlice