//localizer applied
import localizer from './../../localizer'

import { useState } from 'react'        //useEffect
import { View } from 'react-native'
import { Appbar } from 'react-native-paper' //Text
import TitleBar from '../TitleBar'

import PeriodSelector from '../Components/PeriodSelector'
import CalendarInner from './CalendarInner'

import dayjs from 'dayjs'
//import pl from 'dayjs/locale/pl'
//import en from 'dayjs/locale/en'
//dayjs.locale(pl)


//import advancedFormat from 'dayjs/plugin/advancedFormat'
//dayjs.extend(advancedFormat)


export default function Calendar(props) {

    const [month, setMonth] = useState(dayjs().format('YYYY-MM'))


    //disNext={dayjs().format('MMMM YYYY')}

    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={localizer("Calendar")}>
                <Appbar.Action icon="dots-vertical" onPress={() => { props.navigation.getParent().navigate('Settings') }} />
            </TitleBar>

            <PeriodSelector
                name={dayjs(month).format('MMMM YYYY')}
                prev={() => setMonth(dayjs(month).subtract(1, 'month').format('YYYY-MM'))}
                next={() => setMonth(dayjs(month).add(1, 'month').format('YYYY-MM'))}
            />

            <CalendarInner month={month} navigation={props.navigation} />

        </View >
    )
}