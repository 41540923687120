//import dayjs from "dayjs"

export default class vbModel {

    constructor(data) {
        this.data = data
    }


    get personDisp() {

        const lname = this.data.employee.person.last_name === 'Rakotondramiandra-Dudzic' ? 'R.-Dudzic' : this.data.employee.person.last_name

        return this.data.employee.person.first_name
            + (this.data.employee.person.middle_name !== null ? ' ' + this.data.employee.person.middle_name + ' ' : ' ')
            + lname
    }
  


    get personLnDisp() {

        const lname = this.data.employee.person.last_name === 'Rakotondramiandra-Dudzic' ? 'R.-Dudzic' : this.data.employee.person.last_name

        return lname + ' ' + this.data.employee.person.first_name
            + (this.data.employee.person.middle_name !== null ? ' ' + this.data.employee.person.middle_name : '')
            
    }

    

}