import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"


const teamHRequestResultsSorter = (a, b) => {
    if (a.ts_date === b.ts_date) {
        if (a.from_time === b.from_time) {
            return a.id < b.id ? 1 : -1
        }
        return a.from_time < b.from_time ? 1 : -1
    } 
    return a.ts_from < b.ts_from ? 1 : -1
}


const doFetchTeamHRequest = async (authtoken) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.teamhr/?ts_date>=,'+dayjs().format('YYYY')+'-01-01',
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort( teamHRequestResultsSorter )
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchTeamHRequest = createAsyncThunk(
    "fetchTeamHRequest",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const teamHR = await doFetchTeamHRequest(state.userInfo.token)
        if (teamHR.success) {
            return teamHR.data
        }

        return thunkAPI.rejectWithValue(teamHR.error)
    }
)


export const silentFetchTeamHRequest = createAsyncThunk(
    "silentFetchTeamHRequest",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.teamHRequest.current)) {
            return null
        }

        const teamHR = await doFetchTeamHRequest(state.userInfo.token)
        if (teamHR.success) {
            return teamHR.data
        }

        return thunkAPI.rejectWithValue(teamHR.error)
    }
)



const teamHRequestSlice = createSlice({
    name: "teamHRequest",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null
    },

    reducers: {
        unloadTeamHRequest(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }
    },

    extraReducers: (builder) => {

        builder.addCase(fetchTeamHRequest.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchTeamHRequest.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchTeamHRequest.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(silentFetchTeamHRequest.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchTeamHRequest.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(silentFetchTeamHRequest.rejected, (state) => {
            //state.loading = false
            //state.error = true
        })

    },

})

export const { unloadTeamHRequest } = teamHRequestSlice.actions

export default teamHRequestSlice