//localizer applied
import localizer from '../localizer'

import { useEffect, useState } from 'react'
import Loading from './Loading'
import { useDispatch, useSelector } from "react-redux"
import { initializeUserInfo, fetchUserInfo } from '../redux/userInfoSlice'    //silentFetchUserInfo
import { initializeAppConfig } from '../redux/appConfigSlice'

import SignInForm from './SignInForm'
import Stacks from './Stacks'
import Constants from 'expo-constants'

import DisabledApp from './DisabledApp'

//import { registerTranslation } from 'react-native-paper-dates'
import { registerTranslation } from  '../../custom_modules/react-native-paper-dates'


import { setDayjsLocale } from '../localizer'
import { rnpdLocales } from '../localizer'

//import dayjs from "dayjs"
import { inspectUserInfoStore } from '../dispatchers'


//import { AppState } from 'react-native'
import { Platform } from 'react-native'
import { registerBackgroundFetchAsync, unregisterBackgroundFetchAsync } from "../tasks/taskman"
import * as Notifications from 'expo-notifications'
import Toast from 'react-native-root-toast'
import { setNotifications } from '../redux/appConfigSlice'




export default function Main(props) {

  const userInfo = useSelector((state) => state.userInfo)
  const appConfig = useSelector((state) => state.appConfig)
  const dispatch = useDispatch()

  const [tickerSpawned, setTickerSpawned] = useState(false)

  const bckgRegister = async() => {
    const notificationPermissions = await Notifications.requestPermissionsAsync()

    if (notificationPermissions.granted) {
      //console.log('registering...')
      registerBackgroundFetchAsync()
    } else {
      Toast.show( localizer('Notification permissions removed, disabling notifications') )
      dispatch(setNotifications(false) )
    }
  }
  

  useEffect(() => {
    if ((!userInfo.initialized) && (!userInfo.loading)) {
      dispatch(initializeUserInfo())
    } else {
      if ((userInfo.data === null) && (userInfo.signedIn)) {
        dispatch(fetchUserInfo() )
      }
    }

    if ((!appConfig.initialized) && (!appConfig.loading)) {
      dispatch(initializeAppConfig())
    }

    if ((userInfo.initialized) && (!userInfo.loading) && (userInfo.data !== null)) {
      if (!tickerSpawned) {
        setTickerSpawned(true)
        //console.log('spawning...')
        inspectUserInfoStore()
      }
    }

    if ((Platform.OS !== 'web') && ((appConfig.initialized) && (!appConfig.loading))) {
      if ((appConfig.data.notifications) && (userInfo.signedIn)) {
        //console.log('trying to register...')
        bckgRegister()
      } else {
        //console.log('unregistering...')
        unregisterBackgroundFetchAsync()
      }
    }
  })



  if ((!userInfo.initialized) || (!appConfig.initialized) || (userInfo.loading) || (appConfig.loading)) {
    return (
      <Loading main={true} />
    )
  }

  registerTranslation( rnpdLocales[appConfig.data.locale].name, rnpdLocales[appConfig.data.locale].tr )

  setDayjsLocale()

  if (!userInfo.signedIn) {
    return (
      <SignInForm />
    )
  }
  //appConfig.data.locale - pass to react?

  if (userInfo.data === null) {
    return (
      <Loading main={true} />
    )
  }

  if (userInfo.data.appinfo.disabled.includes(Constants.manifest.version)) {
    return (<DisabledApp />)
  }

  return (
    <Stacks />
  )

}

