//localizer applied
import localizer from './../../localizer'

import { useState } from 'react'
import { View, ScrollView } from 'react-native'
import { Portal, Dialog, RadioButton, TouchableRipple } from 'react-native-paper'       //IconButton, Text

import TextComponent from './TextComponent'
import SelectLink from './SelectLink'



export default function SelectComponent(props) {

    const [dialogVisible, setDialogVisible] = useState(false)

    const localSetter = (newValue) => {
        setDialogVisible(false)
        return props.setter(newValue)
    }

    const renderOptions = (node, value, setter) => {
        return (
            <TouchableRipple key={node.v} onPress={() => {
                if (node.v !== value) {
                    setter(node.v)
                }
            }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16, paddingVertical: 8 }}>
                    <View pointerEvents="none">
                        <RadioButton
                            value={node.v}
                            status={node.v === value ? 'checked' : 'unchecked'}
                        />
                    </View>
                    <TextComponent isSubheading style={{ paddingLeft: 8 }}>
                        {node.label}
                    </TextComponent>
                </View>
            </TouchableRipple>
        )
    }

    return (
        <SelectLink label={props.label} sublabel={props.options.filter(node => node.v === props.value).map(node => node.label)} icon={props.icon} action={() => setDialogVisible(true)} emphasizeValue={ props.emphasizeValue }>
            <Portal>
                <Dialog visible={dialogVisible} onDismiss={() => setDialogVisible(false)}>
                    <Dialog.Title>{localizer('Choose an option')}</Dialog.Title>
                    <Dialog.ScrollArea style={{ maxHeight: 370, paddingHorizontal: 0 }}>
                        <ScrollView>
                            <View>
                                {props.options.map(node => renderOptions(node, props.value, localSetter))}
                            </View>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
        </SelectLink>
    )
}