import { createStackNavigator } from '@react-navigation/stack'

import Btabs from './Btabs'
import Settings from './Settings'
import VacationEditor from './Editors/VacationEditor'
import HRequestEditor from './Editors/HRequestEditor'
import CalendarDay from './Calendar/CalendarDay'

const Stack = createStackNavigator()

export default function Calendar() {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Btabs" component={Btabs} options={{ headerShown: false }} />
            <Stack.Screen name="Settings" component={Settings} options={{ headerShown: false }} />
            <Stack.Screen name="VacationEditor" component={VacationEditor} options={{ headerShown: false }} />
            <Stack.Screen name="HRequestEditor" component={HRequestEditor} options={{ headerShown: false }} />
            <Stack.Screen name="CalendarDay" component={CalendarDay} options={{ headerShown: false }} />
        </Stack.Navigator>
    )

}