import { View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import dayjs from "dayjs"
import DbtpRequestsComponent from './DbtpRequestsComponent'

export default function DbtpComponent(props) {

    const themeObject = useTheme()
    const dModel = props.dbtpModel

    const today = dayjs(dayjs().format('YYYY-MM-DD'))
    const isToday = dModel.date.valueOf() === today.valueOf() ? true : false


    let opacity = 1
    if ((props.markMissing) && (isToday) && ((dModel.intimeDisp === 'NA') || (dModel.outtimeDisp !== 'NA'))) {
        opacity = 0.5
    }

    return (
        <View style={{ height: 110, width: '100%', justifyContent: 'center' }} >
            <View style={{ height: 110, alignItems: 'center', width: '100%' }} >
                <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>

                    <View style={{ marginLeft: 15, flex: 1 }}>

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 22, marginBottom: 2, flex: 1, opacity: opacity }}>{props.nameToDisplay}</Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 14, width: 40, color: dModel.data.late ? themeObject.colors.error : themeObject.colors.onPrimaryContainer }}>{dModel.intimeDisp}</Text>
                            <Text style={{ fontSize: 14, width: 16, textAlign: 'center' }}>-</Text>
                            <Text style={{ fontSize: 14, width: 40, height: 42 }}>{dModel.outtimeDisp}</Text>
                            <Text style={{ fontSize: 14, width: 74, height: 42, marginLeft: 15, color: themeObject.colors[dModel.overttimeColor] }}>{(dModel.overtimeDisp)}</Text>

                            <DbtpRequestsComponent dbtpModel={dModel} navigation={props.navigation} />

                        </View>
                    </View>

                </View>
            </View>
        </View>
    )

}