import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import FormData from 'form-data'

import dayjs from "dayjs"

//import Toast from 'react-native-root-toast'
//import { unloadDataStores } from './../dispatchers'

const doFetchUserInfo = async (authtoken) => {

  try {
    const response = await axios({
      method: 'get',
      url: 'https://sam-api-proxy.msi.com/.plbpmahr/',
      headers: {
        'bpma-api-key': authtoken,
      }
    })

    return {
      success: true,
      data: response.data
    }

  } catch (error) {

    return {
      success: false,
      error
    }
  }
}



export const fetchUserInfo = createAsyncThunk(
  "fetchUserInfo",
  async (args, thunkAPI) => {

    const state = thunkAPI.getState().userInfo

    if (state.initialized === false) {
      return thunkAPI.rejectWithValue('not initialized')
    }

    const userInfo = await doFetchUserInfo(state.token)
    if (userInfo.success) {
      return userInfo.data
    }

    return thunkAPI.rejectWithValue(userInfo.error)
  }
)

export const silentFetchUserInfo = createAsyncThunk(
  "silentFetchUserInfo",
  async (args, thunkAPI) => {

    const state = thunkAPI.getState().userInfo

    if (state.initialized === false) {
      return thunkAPI.rejectWithValue('not initialized')
    }

    const userInfo = await doFetchUserInfo(state.token)
    if (userInfo.success) {
      return userInfo.data
    }

    return thunkAPI.rejectWithValue(userInfo.error)
  }
)





export const initializeUserInfo = createAsyncThunk(
  "initializeUserInfo",
  async (args, thunkAPI) => {

    const state = thunkAPI.getState().userInfo

    if (state.initialized) {
      return {
        initialized: true,
        token: state.token
      }
    }

    try {
      let token

      if (Platform.OS === 'web') {
        token = await AsyncStorage.getItem('token')
      } else {
        token = await SecureStore.getItemAsync('token')
      }

      let data = null

      if (token) {
        const userInfo = await doFetchUserInfo(token)

        if (userInfo.success) {
          data = userInfo.data
        } else {
          token = ''

          if (Platform.OS === 'web') {
            AsyncStorage.setItem('token', '')
          } else {
            SecureStore.setItemAsync('token', '')
          }
        }
      }

      return {
        initialized: true,
        token,
        data
      }

    } catch (error) {
      return thunkAPI.rejectWithValue('failed to initialize')
    }
  }
)



export const logIn = createAsyncThunk(
  "logIn",
  async (args, thunkAPI) => {

    const state = thunkAPI.getState().userInfo
    //console.log('logging in')
    //console.log(state)

    if (state.initialized === false) {
      return thunkAPI.rejectWithValue('not initialized')

    }

    //if (state.signedIn === true) {
    //  return thunkAPI.rejectWithValue('already signed in')
    //  return
    //}

    const formData = new FormData()
    formData.append('username', args.username)
    formData.append('password', args.password)

    try {
      //console.log('log in trying...')

      const response = await axios({
        method: 'post',
        url: 'https://sam-api-proxy.msi.com/.plbpmahr/.login/',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })

      //console.log('concluded')
      //console.log(response.data)

      const token = response.data.authtoken_id.toString()

      if (Platform.OS === 'web') {
        await AsyncStorage.setItem('token', token)
      } else {
        await SecureStore.setItemAsync('token', token)
      }


      //thunkAPI.dispatch(fetchUserInfo() )

      //console.log('successful login...')
      return {
        signedIn: true,
        token
      }
    }
    catch (error) {
      return thunkAPI.rejectWithValue('failed')
    }
  }
)


export const logOut = createAsyncThunk(
  "logOut",
  async (args, thunkAPI) => {

    const state = thunkAPI.getState().userInfo

    if (state.initialized === false) {
      return thunkAPI.rejectWithValue('not initialized')
    }

    if (state.signedIn !== true) {
      return thunkAPI.rejectWithValue('not signed in')
    }

    //unloadDataStores()

    try {

      if (Platform.OS === 'web') {
        await AsyncStorage.setItem('token', '')
      } else {
        await SecureStore.setItemAsync('token', '')

        await axios({
          method: 'get',
          url: 'https://sam-api-proxy.msi.com/.plbpmahr/.logout/',
          headers: {
            'bpma-api-key': authtoken,
          }
        })
      }

      return {
        signedIn: false
      }

    } catch (error) {
      return thunkAPI.rejectWithValue('failed to unset token')
    }
  }
)





const userInfoSlice = createSlice({
  name: "userInfo",

  initialState: {
    initialized: false,
    loading: false,
    error: false,
    data: null,
    lastUpdate: null,
    signedIn: null,
    token: null
  },

  reducers: {
    //// -non-thunk reducers
    //increment: (state) => {
    //    state.count += 1;
    //},
  },

  extraReducers: (builder) => {

    //fetchUserInfo
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.loading = true
      state.error = false
    })

    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      //state.entities.push(action.payload)
      state.loading = false
      state.error = false
      state.data = action.payload
      state.lastUpdate = dayjs().valueOf()
    })

    builder.addCase(fetchUserInfo.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    //silentFetchUserInfo
    builder.addCase(silentFetchUserInfo.pending, (state) => {
      //state.loading = true
      state.error = false
      state.lastUpdate = dayjs().valueOf()
    })

    builder.addCase(silentFetchUserInfo.fulfilled, (state, action) => {
      state.loading = false
      state.error = false
      state.data = action.payload
      state.lastUpdate = dayjs().valueOf()
    })

    builder.addCase(silentFetchUserInfo.rejected, (state) => {
      state.loading = false
      state.error = true
    })



    //initializeUserInfo
    builder.addCase(initializeUserInfo.pending, (state) => {
      state.loading = true
      state.error = false
    })

    builder.addCase(initializeUserInfo.fulfilled, (state, action) => {
      state.loading = false
      state.error = false
      state.initialized = true
      state.token = action.payload.token
      const token = action.payload.token ? action.payload.token : ''
      state.signedIn = token ? true : false
      state.data = action.payload.data
      state.lastUpdate = dayjs().valueOf()
    })

    builder.addCase(initializeUserInfo.rejected, (state) => {
      state.loading = false
      state.error = true
    })


    //logIn
    builder.addCase(logIn.pending, (state) => {
      state.loading = true
      state.error = false
    })

    builder.addCase(logIn.fulfilled, (state, action) => {
      //console.log('REDUCER: login success')
      state.loading = false
      state.error = false
      state.token = action.payload.token
      state.signedIn = action.payload.signedIn
    })

    builder.addCase(logIn.rejected, (state) => {
      //console.log('REDUCER: login rejected')
      state.loading = false
      state.error = false
      state.token = null
      state.signedIn = false
      state.data = null
    })


    //logOut
    builder.addCase(logOut.pending, (state) => {
      state.loading = true
      state.error = false
    })

    builder.addCase(logOut.fulfilled, (state) => {
      state.loading = false
      state.error = false
      state.token = null
      state.signedIn = false
      state.data = null
    })

    builder.addCase(logOut.rejected, (state) => {
      //state.initialized = false
      state.loading = false
      state.error = true
      state.token = null
      state.signedIn = false
      state.data = null
    })


  },

})


//export const { increment, decrement.... } = userInfoSlice.actions;
export default userInfoSlice