//localizer applied
import localizer from './../../localizer'

import { useEffect } from 'react'
import { View, FlatList } from 'react-native'
import { Text, TouchableRipple, IconButton, useTheme } from 'react-native-paper'
import { useDispatch, useSelector } from "react-redux"

import { fetchMyHRequest } from '../../redux/myHRequestSlice'
import RequestsFAB from './RequestsFAB'
import Loading from '../Loading'

import EmptyPlaceholder from '../Components/EmptyPlaceholder'
import hrequestModel from '../../models/hrequestModel'


export default function HRequestsListInner(props) {

    const themeObject = useTheme()

    const dispatch = useDispatch()
    const myHRequest = useSelector((state) => state.myHRequest)

    useEffect(() => {
        if ((!myHRequest.current) && (!myHRequest.loading)) {
            dispatch(fetchMyHRequest())
        }
    })

    if (!myHRequest.current) {
        return <Loading />
    }


    const itemRenderer = (hrequest) => {
        const hrModel = new hrequestModel(hrequest.item)


        /*
                        {vModel.activeBalance ? (
                            <View style={{ marginLeft: 18, marginRight: 24, width: 55, alignItems: 'center' }}>
                                <Text style={{ fontSize: 22 }}>{vModel.balanceDisp}</Text>
                                <Text style={{ fontSize: 14 }}>days</Text>
                            </View>) : null}
        */

        return (
            <View style={{ height: 110, width: '100%', justifyContent: 'center', opacity: hrModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.getParent().getParent().navigate('HRequestEditor', { model: hrModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={hrModel.icon} /></View>
                        <View style={{ flex: 1 }}>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 22, marginBottom: 2, flex: 1 }}>{ hrModel.dateDisp }</Text>
                                <Text style={{ fontSize: 22, width: 145, textAlign: 'right', marginBottom: 2, marginRight: 40, opacity: 0.65 }}>{ hrModel.timeRange }</Text>                              
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, color: themeObject.colors[hrModel.color], flex: 1 }}>{hrModel.typeStatusDesc}</Text>                       
                                <Text style={{ fontSize: 14, width: 50, textAlign: 'right', marginBottom: 2, marginRight: 40 }}>{ hrModel.durationHhhmm }h</Text>       
                            </View>



                        </View>


                    </View>
                </TouchableRipple>
            </View>
        )


    }


    const listRenderer = () => {
        return (
            <FlatList
                data={myHRequest.data}
                renderItem={itemRenderer}
                keyExtractor={node => node.id.toString()}

                contentContainerStyle={{ paddingBottom: 60 }}
            />
        )
    }


    return (
        <View style={{ flex: 1 }}>

            {((myHRequest.data === null) || (myHRequest.data.length === 0)) ? (<EmptyPlaceholder message={localizer("No requests found")} />) : listRenderer()}

            <RequestsFAB navigation={props.navigation} />
        </View>
    )

}