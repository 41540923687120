//localizer applied
import localizer from './../localizer'

import dayjs from "dayjs"
//import localizer from '../localizer'
//import { View, FlatList } from 'react-native'
//import { Text, TouchableRipple, IconButton } from 'react-native-paper'
import axios from "axios"

import { unloadDataStores, refetchUserInfo } from '../dispatchers'


export default class vacationModel {

    #start = null
    #end = null

    constructor(data, userInfo = null) {
        this.data = data
        this.userInfo = userInfo

        this.draft()
    }


    static nonConfirmationRequired = ['add', 'adjust', 'sickleave', 'unexcused']


    insertUserInfo(userInfo) {
        if (this.userInfo === null) {
            this.userInfo = userInfo
        }

        //this.draft()
    }


    get hasData() {
        return this.data instanceof Object && Object.keys(this.data).length ? true : false
    }

    get hasUserInfo() {
        return this.userInfo instanceof Object && Object.keys(this.userInfo).length ? true : false
    }

    get debug() {
        return {
            class: 'vacationModel',
            hasData: this.hasData,
            hasUserInfo: this.hasUserInfo,
            id: this.hasData ? this.data.id : null,
            isDraft: this.isDraft,
        }
    }

    get isMy() {
        if (!this.hasUserInfo) return null
        return this.userInfo.employee.id === this.data.employee_id ? true : false
    }

    get isEditable() {
        if (!this.hasUserInfo) return null

        if (this.isDraft) return true
        if (!this.isMy) return false
        if (['add', 'adjust', 'unexcused', 'sickleave'].includes(this.data.type)) return false
        if ([1, 2].includes(this.data.confirmed)) return false

        if (this.data.confirmed === 0) {
            return true
        }
        return this.end.valueOf() > dayjs().valueOf() ? true : false
    }


    get isPending() {
        return this.statusDesc === 'pending' ? true : false
    }


    get isManagedByMe() {
        if (!this.hasUserInfo) return null
        return this.userInfo.manages_employee_ids.includes(this.data.employee_id) ? true : false
    }


    get isPendingToMe() {
        if (!this.hasUserInfo) return null
        return this.isPending && this.isManagedByMe ? true : false
    }


    get isEvent() {
        return ['add', 'adjust', 'unexcused'].includes(this.data.type) ? true : false
    }

    get isBalanceEvent() {
        return ['add', 'adjust'].includes(this.data.type) ? true : false
    }


    draft() {
        if (((!this.hasData) || (!this.data.hasOwnProperty('id'))) && (this.hasUserInfo)) {
            this.data = {
                class: 'vacation',
                id: null,
                employee_id: this.userInfo.employee.id,
                deputy_employee_id: 0,
                //year: dayjs().format('YYYY'),
                type: 'book', //?? or null
                balance: null,
                ts_start: null,
                ts_end: null,
                comment: null,
                //confirmed_user_id: null
                //ts_created: "2022-10-03 16:41:20",
                //"ts_confirmed": null,
                confirmed: 0,
                //dbtpwsrule_ids: null,
                //extra_info: null
                ...this.data
            }
        }
    }

    get isDraft() {
        return (this.hasData && this.data.id !== null && this.data.id !== 0) ? false : true
    }

    static capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    get editorTitle() {
        if (!this.hasUserInfo) return localizer('Unknown Context')



        if (this.isEvent) {
            if (this.isMy) {
                return localizer('Event related to me')
            }

            if (this.isManagedByMe) {
                return localizer('Event related to my employee')
            }

            return localizer('Other event')
        }

        if (this.isDraft) {
            return localizer('New vacation request')
        }

        if (this.isMy) {
            return localizer('My') + ' ' + localizer(this.statusDesc) + ' '+localizer('request')
        }

        if (this.isPendingToMe) {
            return localizer('Request pending to me')
        }

        return vacationModel.capitalizeFirstLetter(localizer(this.statusDesc)) + ' '+ localizer('request')
    }




    get icon() {
        return vacationModel.typeToIcon(this.data.type)
    }

    static typeToIcon(type) {
        switch (type) {
            case 'book': return 'umbrella-beach-outline'
            case 'unpaidbook': return 'currency-usd-off'
            case 'ondemand': return 'message-alert-outline'
            case 'occasional': return 'church'
            case 'childcare': return 'baby-carriage'
            case 'sickleave': return 'needle'
            case 'unexcused': return 'cancel'
            case 'excused': return 'check-circle-outline'
            case 'excusedpaid': return 'currency-usd'
            case 'homeoffice': return 'home-city-outline'
            case 'biztrip': return 'airplane'
            case 'add': return 'calendar-plus'
            case 'adjust': return 'calendar-edit'
            default: return 'help-circle-outline'
        }
    }


    get typeDesc() {
        return vacationModel.typeToDesc(this.data.type)
    }

    static typeToDesc(type) {
        switch (type) {
            case 'book': return localizer('vacation request')
            case 'unpaidbook': return localizer('unpaid vacation request')
            case 'ondemand': return localizer('on-demand vacation request')
            case 'occasional': return localizer('occasional holiday request')
            case 'childcare': return localizer('child care request')
            case 'sickleave': return localizer('sickleave')
            case 'unexcused': return localizer('unexcused absence event')
            case 'excused': return localizer('excused absence event')
            case 'excusedpaid': return localizer('excused paid absence event')
            case 'homeoffice': return localizer('home office request')
            case 'biztrip': return localizer('business trip request')
            case 'add': return localizer('add balance system event')
            case 'adjust': return localizer('adjust balance system event')
            default: return localizer('other event')
        }
    }

    static type = ['book', 'unpaidbook', 'ondemand', 'occasional', 'unpaidbook', 'childcare', 'sickleave', 'excused', 'excusedpaid', 'biztrip', 'unexcused', 'add', 'adjust']
    static editableTypes = ['book', 'unpaidbook', 'ondemand', 'occasional', 'childcare', 'excused', 'excusedpaid', 'homeoffice', 'biztrip']
    static deputyRequiredTypes = ['book', 'unpaidbook']
    static deputyPossibleTypes = ['book', 'ondemand', 'occasional', 'unpaidbook', 'childcare', 'excused', 'excusedpaid', 'biztrip', 'unexcused', 'sickleave']





    get statusDesc() {
        if (vacationModel.nonConfirmationRequired.includes(this.data.type)) {
            return 'in effect'
        }

        switch (this.data.confirmed) {
            case 0: return 'pending'
            case 1: return 'cancelled'
            case 2: return 'rejected'
            case 3: return 'confirmed'
        }
    }

    get localizedStatusDesc() {
        //return localizer(this.statusDesc)
        switch (this.data.type) {
            case 'book': return localizer(this.statusDesc)
            case 'unpaidbook': return localizer(this.statusDesc)
            case 'ondemand': return localizer(this.statusDesc)
            case 'occasional': return localizer(this.statusDesc)
            case 'childcare': return localizer('(rż)'+this.statusDesc)
            case 'sickleave': return localizer('(rn)'+this.statusDesc)
            case 'unexcused': return localizer('(rż)'+this.statusDesc)
            case 'excused': return localizer('(rż)'+this.statusDesc)
            case 'excusedpaid': return localizer('(rż)'+this.statusDesc)
            case 'homeoffice': return localizer(this.statusDesc)
            case 'biztrip': return localizer(this.statusDesc)
            case 'add': return localizer('(rn)'+this.statusDesc)
            case 'adjust': return localizer('(rn)'+this.statusDesc)
            default: return localizer(this.statusDesc)
        }
    }



    get typeStatusDesc() {
        if (vacationModel.nonConfirmationRequired.includes(this.data.type)) {
            return this.typeDesc
        }

        return this.localizedStatusDesc + ' ' + this.typeDesc
    }


    get opacity() {
        if (vacationModel.nonConfirmationRequired.includes(this.data.type)) {
            return 1
        }

        if (this.data.confirmed === 3) return 1
        if (this.data.confirmed === 0) return 0.9
        return 0.7
    }


    get start() {
        if (this.#start === null) {
            this.#start = this.data.ts_start === null ? false : dayjs(this.data.ts_start)
        }
        return this.#start
    }

    get end() {
        if (this.#end === null) {
            this.#end = this.data.ts_end === null ? false : dayjs(this.data.ts_end)
        }
        return this.#end
    }


    get activeBalance() {
        return ([0, 3].includes(this.data.confirmed))
    }


    get balanceDisp() {
        return (this.data.balance > 0) ? '+' + this.data.balance : this.data.balance
    }


    get dateRangeDisp() {
        if (!this.start) {
            return localizer('balance event')
        }

        if (this.start.valueOf() == this.end.valueOf()) {
            return this.start.format('MMM D')
        }

        return this.start.format('MMM D') + ' - ' + this.end.format('MMM D')
    }

    get color() {
        switch (this.statusDesc) {
            case 'pending': return 'primary'
            case 'cancelled': return 'tertiary'
            case 'rejected': return 'error'
        }

        return 'onBackground'
    }


    get personDisp() {

        if ((!this.data.hasOwnProperty('employee'))
            || ((this.hasUserInfo) && (this.data.employee_id === this.userInfo.employee.id))
        ) {
            return localizer('me')
        }

        const lname = this.data.employee.person.last_name === 'Rakotondramiandra-Dudzic' ? 'R.-Dudzic' : this.data.employee.person.last_name

        return this.data.employee.person.first_name
            + (this.data.employee.person.middle_name !== null ? ' ' + this.data.employee.person.middle_name + ' ' : ' ')
            + lname
    }


    save(payload, token) {

        payload = {
            ...payload,
            mode: 'request',
            employee_id: this.data.employee_id
        }

        if (!this.isDraft) {
            payload.id = this.data.id
        }

        if ((payload.deputy_employee_id) && (payload.type) && (!vacationModel.deputyPossibleTypes.includes(payload.type))) {
            payload.deputy_employee_id = 0
        }

        return this.doSave(payload, token, 'myvacation')
    }


    cancel(payload, token) {
        payload.id = this.data.id
        payload.confirmed = 1
        return this.doSave(payload, token, 'myvacation')
    }

    approve(payload, token) {
        payload.id = this.data.id
        return this.doSave(payload, token, 'teamvacation')
    }



    doSave(payload, token, method) {
        return new Promise((resolve, reject) => {

            //console.log(payload)

            axios({
                method: 'post',
                url: 'https://sam-api-proxy.msi.com/.plbpmahr/.' + method + '/',
                headers: {
                    'bpma-api-key': token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(payload)
            })
                .then((resp) => {
                    unloadDataStores()
                    refetchUserInfo()
                    resolve()
                })
                .catch((error) => {
                    let msg

                    if (typeof error.response.data === 'object') {

                        if (typeof error.response.data.error_message === 'string') {
                            msg = localizer(error.response.data.error_message)
                        }

                        if (typeof error.response.data.error_message === 'object') {
                            const errorJson = error.response.data.error_message

                            if (errorJson.deputy_employee_id) {
                                msg = localizer('Please sepcify a deputy.')
                            }

                            if (errorJson.balance) {
                                if (errorJson.balance.calculate_balance_adj) {
                                    msg = localizer('Unable to detect active workschedule for specified time frame.')
                                }
    
                                if (errorJson.balance.check_if_balance_within_limits) {
                                    msg = localizer('Insufficent' + (payload.type === 'ondemand' ? ' ondemand' : '') + ' vacation balance to place this request.')
                                }
                            }

                            if ((errorJson.ts_start) || (errorJson.ts_end)) {
                                msg = localizer('Please sepcify date range correctly.')
                            }
                        }
                    }

                    if (!msg) {
                        console.log(error.response.data.error_message)
                        msg = localizer('Operation failed for unknown reason.')
                    }

                    reject(msg)
                })
        })
    }

}