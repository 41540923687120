//localizer applied
import localizer from './../../localizer'

import { useEffect } from 'react'       //useState, useCallback, 
import { View } from 'react-native'
import { Text, useTheme, TouchableRipple, IconButton } from 'react-native-paper'        //Button, Chip

import { useSelector, useDispatch } from "react-redux"
import { setCalendarDate, fetchCalendar } from '../../redux/calendarSlice'

import Loading from '../Loading'

import calendarModel from '../../models/calendarModel'


import dayjs from 'dayjs'
//import weekOfYear from 'dayjs/plugin/weekOfYear'
//dayjs.extend(weekOfYear)
//import weekYear from 'dayjs/plugin/weekYear'
//dayjs.extend(weekYear)
//import isoWeek from 'dayjs/plugin/isoWeek'
//dayjs.extend(isoWeek)



export default function CalendarInner(props) {
    const themeObject = useTheme()

    const dispatch = useDispatch()
    const calendar = useSelector((state) => state.calendar)
    const userInfo = useSelector((state) => state.userInfo)


    const weekDayLabels = [localizer('Mon'), localizer('Tue'), localizer('Wed'), localizer('Thu'), localizer('Fri'), localizer('Sat'), localizer('Sun')]
    const today = dayjs()

    const monthStart = dayjs(props.month)
    const monthStartWeekday = monthStart.day()
    const calStart = monthStartWeekday === 1 ? monthStart : monthStartWeekday === 0 ? monthStart.subtract(6, 'day') : monthStart.subtract(monthStartWeekday - 1, 'day')
    const monthEnd = monthStart.add(1, 'month').subtract(1, 'day')
    //const calEnd = calStart.add(41,'day')

    useEffect(() => {
        if (calendar.date !== monthStart.format('YYYY-MM-DD') ) {
            dispatch( setCalendarDate(monthStart.format('YYYY-MM-DD')) )
        }

        if ((!calendar.current) && (!calendar.loading)) {
            dispatch( fetchCalendar() )
        }       
    })


    const calendarNodes = []
    let day, weekArray = []

    for (let i = 0; i < 42; i++) {
        day = calStart.add(i, 'day')

        weekArray.push({
            day,
            dayOfMonth: day.date(),
            dayOfWeek: day.day(),
            //dayDisp: day.format('YYYY-MM-DD'),
        })

        if (day.day() === 0) {
            calendarNodes.push(weekArray)
            weekArray = []
        }
    }

    if ((!calendar.current) || (calendar.loading)) {
        return (<Loading />)
    }


    const calModel = new calendarModel(calendar.data, userInfo.data, calStart, calStart.add(41,'day'))
  


    const eventRenderer = (xModel, color, backgroundColor) => {

        const opacity = xModel.statusDesc === 'pending' ? 0.75 : 1

        return(
            <View key={ xModel.data.class+xModel.data.id } style={{ opacity: opacity, backgroundColor: backgroundColor, marginBottom: 1, padding: 2, paddingLeft: 2, borderRadius: 3, flexDirection: 'row' }}>
                <IconButton icon={ xModel.icon } iconColor={ color } size={ 11 } style={{ margin: -7 }} />
                <View style={{ marginLeft: 2, flex: 1}}>
                    <Text numberOfLines={1} style={{ fontSize: 9, color: color, overflow: 'hidden' }} >
                    { xModel.personDisp }
                    </Text>
                </View>
            </View>
        )

    }

    const renderCalendarNode = (dayNode) => {
        const isCurrent = dayNode.day.valueOf() < monthStart.valueOf() || dayNode.day.valueOf() > monthEnd.valueOf() ? false : true
        const isToday = dayNode.day.format('YYYYMMDD') === today.format('YYYYMMDD') ? true : false
        const ts = dayNode.day.valueOf()

        const events = calModel.getEventsByDay(ts)
        const dayColor = ([6,0].includes(dayNode.day.day()) || events.isHoliday) ? themeObject.colors.error : themeObject.colors.onBackground

        let rendered = 0, count = 0

        return (
            <View key={ 'd'+dayNode.day.format('MMDD') } style={{ flex: 1, borderColor: themeObject.colors.secondaryContainer, borderTopWidth: 1, borderLeftWidth: dayNode.dayOfWeek === 1 ? 0 : 1 }}>
                <TouchableRipple style={{ 
                    flex: 1,
                    backgroundColor: isToday ? themeObject.colors.secondaryContainer : isCurrent ? null : themeObject.colors.surfaceVariant,
                    opacity: isCurrent ? 1 : 0.5
                    }} onPress={ () => { props.navigation.navigate('CalendarDay', { timestamp: dayNode.day.valueOf() }) } }>
                    <View style={{ flex: 1 }}>
                        <Text style={{ textAlign: "center", color: dayColor }}>{dayNode.dayOfMonth}</Text>
                        <View style={{ flex: 1 }}>
                        {
                            events.myHr.map( xModel => {
                                count++

                                if (count < 4) {
                                    rendered++
                                    return eventRenderer(xModel, themeObject.colors.onTertiary, themeObject.colors.tertiary ) 
                                }

                                return null
                            })
                        }

                        {   
                            events.myVacation.map( xModel => {
                                count++

                                if (count < 4) {
                                    rendered++
                                    return eventRenderer(xModel, themeObject.colors.onPrimary, themeObject.colors.primary ) 
                                }

                                return null
                            })
                        }

                        {
                            events.teamHr.map( xModel => {
                                count++

                                if (count < 4) {
                                    rendered++
                                    return eventRenderer(xModel, themeObject.colors.onTertiary, themeObject.colors.tertiary ) 
                                }

                                return null
                            })
                        }


                        {
                            events.teamVacation.map( xModel => {
                                count++

                                if (count < 4) {
                                    rendered++
                                    return eventRenderer(xModel, themeObject.colors.onPrimary, themeObject.colors.primary ) 
                                }

                                return null
                            })
                        }

                        {
                            count > rendered ? (
                                <Text style={{ fontSize: 9, paddingTop: 2, paddingLeft: 3 }}>...{ count-rendered } more</Text>
                            ) : null


                        }

                        </View>
                    </View>
                </TouchableRipple>
            </View>
        )
    }


    return (
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                {
                    weekDayLabels.map((wdLabel, index) => (
                        <View key={'wl'+index} style={{ height: 25, flex: 1, justifyContent: "center", borderColor: themeObject.colors.secondaryContainer, borderLeftWidth: index === 0 ? 0 : 1 }}><Text style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>{wdLabel}</Text></View>
                    ))
                }
            </View>
            {
                calendarNodes.map((calWeekArray, index) => {
                    return (
                        <View key={ 'w'+index } style={{ flexDirection: 'row', flex: 1 }}>
                            {
                                calWeekArray.map(dayNode => renderCalendarNode(dayNode))
                            }
                        </View>
                    )
                })
            }

        </View>
    )

}