//localizer applied
import localizer from '../localizer'

//import { useState } from 'react'
import { View, ScrollView, Platform } from 'react-native'
import { Appbar, Text } from 'react-native-paper'         //Text, TouchableRipple,  IconButton
import Toast from 'react-native-root-toast'
import { useDispatch, useSelector } from "react-redux"

import SelectComponent from './Components/SelectComponent'
import SelectLink from './Components/SelectLink'

import Constants from 'expo-constants'


import TitleBar from './TitleBar'
import { logOut } from '../redux/userInfoSlice'
import { setLocale, setNotifications } from '../redux/appConfigSlice'

import * as Notifications from 'expo-notifications'

import { unloadDataStores } from './../dispatchers'


export default function Settings(props) {
    const appConfig = useSelector((state) => state.appConfig)
    const dispatch = useDispatch()

    const logOutAction = () => {
        dispatch(logOut()).then(() => {
            unloadDataStores()
            Toast.show( localizer('Successfully logged out') )
        })
    }

    const setLocaleAction = (newLocale) => {
        dispatch(setLocale(newLocale)).then(() => {
            Toast.show( localizer('Application language changed') )
        })
    }

    const setNotificationsAction = async (newValue) => {
        if (newValue) {
            const notificationPermissions = await Notifications.requestPermissionsAsync()
            if (!notificationPermissions.granted) {
                Toast.show( localizer('Please grant this app notifications permissions first') )
                return
            }
        }

        dispatch(setNotifications(newValue)).then(() => {
            Toast.show( localizer('Notification preferences changed') )
        })
    }


    const localeOptions = [
        { v: 'en', label: 'English' },
        { v: 'pl', label: 'polski' },
        { v: 'cn', label: '中文' },
    ]

    const notificationsOptions = [
        { v: true, label: localizer('enabled') },
        { v: false, label: localizer('disabled') }
    ]


    return (
        <View style={{ flex: 1 }}>
            <TitleBar title={localizer("Settings")} >
                <Appbar.Action icon="arrow-left" onPress={() => { props.navigation.goBack() }} />
            </TitleBar>

            <ScrollView>
                <SelectComponent value={appConfig.data.locale} options={localeOptions} setter={setLocaleAction} icon="translate" label={localizer("Application language")}></SelectComponent>
                {
                    Platform.OS === 'web' ? 
                    <SelectLink label={localizer("Notifications")} sublabel={localizer('disabled')} icon="bell" disabled />
                    :
                    (<SelectComponent value={appConfig.data.notifications} options={notificationsOptions} setter={setNotificationsAction} icon="bell" label={localizer("Notifications")}></SelectComponent>)
                    
                }

                
                <SelectLink label={localizer("Log out")} icon="exit-to-app" action={logOutAction} />
            </ScrollView>
            <View style={{ height: 80, padding: 20, marginBottom: 35 }}>
                <Text style={{ fontSize: 11 }}>MSI HR Mobile Application, build: {Constants.manifest.version}</Text>
                <Text style={{ fontSize: 11 }}>by Robert Chaba, MSI Polska Sp. z o.o.</Text>
                <Text style={{ fontSize: 11 }}>developement preview</Text>

            </View>

        </View>
    )
}