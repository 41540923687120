import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import dayjs from "dayjs"

const myVacationResultsSorter = (a, b) => {
    if ((a.ts_from === null) || (b.ts_from === null)) {
        if (a.ts_created === b.ts_created) return a.id > b.id ? -1 : 1
        return a.ts_created > b.ts_created ? -1 : 1
    }

    if (a.ts_from === b.ts_from) return a.id > b.id ? -1 : 1
    return a.ts_from > b.ts_from ? -1 : 1
}




const doFetchMyVacation = async (authtoken) => {

    try {
        const response = await axios({
            method: 'get',
            url: 'https://sam-api-proxy.msi.com/.plbpmahr/.myvacation/?year=='+dayjs().format('YYYY'),
            headers: {
                'bpma-api-key': authtoken,
            }
        })

        return {
            success: true,
            data: response.data.sort( myVacationResultsSorter )
        }

    } catch (error) {

        return {
            success: false,
            error
        }
    }
}


export const fetchMyVacation = createAsyncThunk(
    "fetchMyVacation",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if (state.userInfo.initialized === false) {
            return thunkAPI.rejectWithValue('not initialized')
        }

        const myVacation = await doFetchMyVacation(state.userInfo.token)
        if (myVacation.success) {
            return myVacation.data
        }

        return thunkAPI.rejectWithValue(myVacation.error)
    }
)

export const silentFetchMyVacation = createAsyncThunk(
    "silentFetchMyVacation",
    async (args, thunkAPI) => {

        const state = thunkAPI.getState()

        if ((!state.userInfo.token) || (!state.myVacation.current)) {
            return null
        }

        const myVacation = await doFetchMyVacation(state.userInfo.token)
        if (myVacation.success) {
            return myVacation.data
        }

        return thunkAPI.rejectWithValue(myVacation.error)
    }
)



const myVacationSlice = createSlice({
    name: "myVacation",

    initialState: {
        current: false,
        loading: false,
        error: false,
        data: null
    },

    reducers: {
        unloadMyVacation(state) {
            state.current = false
            state.error = false
            state.loading = false
            state.data = null
        }
    },

    extraReducers: (builder) => {

        builder.addCase(fetchMyVacation.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(fetchMyVacation.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.current = true
            state.data = action.payload
        })

        builder.addCase(fetchMyVacation.rejected, (state) => {
            state.loading = false
            state.error = true
        })

        builder.addCase(silentFetchMyVacation.pending, (state) => {
            //state.loading = true
            //state.error = false
        })

        builder.addCase(silentFetchMyVacation.fulfilled, (state, action) => {
            //state.loading = false
            //state.error = false
            //state.current = true
            state.data = action.payload
            if (action.payload === null) {
                state.current = false
            }
        })

        builder.addCase(silentFetchMyVacation.rejected, (state) => {
            //state.loading = false
            state.error = true
        })


    },

})

export const { unloadMyVacation } = myVacationSlice.actions

export default myVacationSlice