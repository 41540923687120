//localizer applied
import localizer from './../../localizer'

import { useState, useEffect } from 'react'
import { View, FlatList } from 'react-native'
import { Text, TouchableRipple, IconButton, useTheme } from 'react-native-paper'

import { useDispatch, useSelector } from "react-redux"

import { fetchTeamHRequest } from '../../redux/teamHRequestSlice'

import hrequestModel from '../../models/hrequestModel'

import dayjs from 'dayjs'

import HeaderSwitch from '../Components/HeaderSwitch'
import PeriodSelector from '../Components/PeriodSelector'
import Loading from '../Loading'
import EmptyPlaceholder from '../Components/EmptyPlaceholder'


export default function TeamHRequestsList(props) {

    const themeObject = useTheme()

    const [filtered, setFiltered] = useState(true)
    const [month, setMonth] = useState(dayjs().format('YYYY-MM'))

    const dispatch = useDispatch()
    const teamHRequest = useSelector((state) => state.teamHRequest)

    useEffect(() => {
        if ((!teamHRequest.current) && (!teamHRequest.loading)) {
            dispatch(fetchTeamHRequest())
        }
    })

    if (teamHRequest.current !== true) {
        return <Loading />
    }


    const hrequests = teamHRequest.data === null ? [] : filtered ?
        teamHRequest.data.map(HRequestNode => new hrequestModel(HRequestNode)).filter(hrModel => hrModel.statusDesc === 'pending') :
        teamHRequest.data.map(HRequestNode => new hrequestModel(HRequestNode)).filter(hrModel => hrModel.date.format('YYYY-MM') === month)




    const itemRenderer = (hrWrapped) => {

        const hrModel = hrWrapped.item

        return (
            <View style={{ height: 110, width: '100%', justifyContent: 'center', opacity: hrModel.opacity }} >
                <TouchableRipple style={{ height: 110, alignItems: 'center', width: '100%' }} onPress={() => { props.navigation.getParent().getParent().navigate('HRequestEditor', { model: hrModel }) }}>
                    <View style={{ flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center' }}>
                        <View style={{ marginLeft: 15, marginRight: 15 }}><IconButton icon={hrModel.icon} /></View>
                        <View style={{ flex: 1 }}>

                            <View style={{ flexDirection: 'row', marginTop: -16 }}>
                                <Text style={{ fontSize: 14, marginBottom: 2, flex: 1 }}>{hrModel.dateDisp}</Text>
                                <Text style={{ fontSize: 14, width: 145, textAlign: 'right', marginBottom: 2, marginRight: 40, opacity: 0.65 }}>{hrModel.timeRange}</Text>
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 18, marginBottom: 2 }}>{hrModel.personDisp}</Text>
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, color: themeObject.colors[hrModel.color], flex: 1 }}>{hrModel.typeStatusDesc}</Text>
                                <Text style={{ fontSize: 14, width: 50, textAlign: 'right', marginBottom: 2, marginRight: 40 }}>{hrModel.durationHhhmm}h</Text>
                            </View>
                        </View>
                    </View>
                </TouchableRipple>
            </View>
        )
    }


    const listRenderer = () => {
        return (
            <FlatList
                data={hrequests}
                renderItem={itemRenderer}
                keyExtractor={hrModel => hrModel.data.id.toString()}
            />
        )
    }

    return (
        <View style={{ flex: 1 }}>
            <HeaderSwitch enabled={filtered} setter={setFiltered} label={localizer("show pending requests only")} />
            {filtered ? null : (
                <PeriodSelector
                    name={dayjs(month).format('MMMM YYYY')}
                    prev={() => setMonth(dayjs(month).subtract(1, 'month').format('YYYY-MM'))}
                    next={() => setMonth(dayjs(month).add(1, 'month').format('YYYY-MM'))}
                    disPrev={dayjs(dayjs().format('YYYY') + '-01-01').format('MMMM YYYY')}
                />
            )}
            {(hrequests.length === 0) ? (<EmptyPlaceholder message={localizer("No requests found")} />) : listRenderer()}
        </View>
    )
}
