//localizer applied
import localizer from '../localizer'

import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
//import { useTheme } from 'react-native-paper'

import { useSelector } from "react-redux"

import Home from './Home/Home'
import Requests from './Requests/Requests'
import Calendar from './Calendar/Calendar'
import MyTeam from './MyTeam/MyTeam'


const Tab = createMaterialBottomTabNavigator();

export default function Btabs(props) {
    const userInfo = useSelector((state) => state.userInfo)
    let badgeNumber = userInfo.data.vacation_pending_count + userInfo.data.hrequest_pending_count
   
    if (!badgeNumber) {
        badgeNumber = null
    }

    //userInfo.data.manages_employee_ids.length


    //let pendingRequests
    
    return (
        <Tab.Navigator>
            <Tab.Screen name={localizer('Home')}
                component={Home}
                options={{
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="home-outline" color={color} size={26} />
                    ),
                }}
            />

            <Tab.Screen name={localizer('Calendar')}
                component={Calendar}
                options={{
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="calendar" color={color} size={26} />
                    ),
                }}
            />

            <Tab.Screen name={localizer('Requests')}
                component={Requests}
                options={{
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="account" color={color} size={26} />
                    ),
                }}
            />

            {
                userInfo.data.manages_employee_ids.length === 0 ? null : (
                    <Tab.Screen name={localizer('My Team')}
                    component={MyTeam}
                    options={{
                        tabBarBadge: badgeNumber,
                        tabBarIcon: ({ color }) => (
                            //color={themeObject.colors.surfaceDisabled} 
                            <MaterialCommunityIcons name="account-group" color={color} size={26} />
                        ),
                    }}
                />
                )

            }



        </Tab.Navigator>
    )
}


