//localizer applied
import localizer from './../../localizer'

import { useState, useCallback, useEffect } from 'react'

import { View, ScrollView, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native'
import { Appbar, TextInput, Button, useTheme } from 'react-native-paper'        //Text


import { useSelector } from "react-redux"           //useDispatch

//import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates'
import { DatePickerModal, TimePickerModal } from '../../../custom_modules/react-native-paper-dates'


import { rnpdLocales } from '../../localizer'

import Toast from 'react-native-root-toast'

import TitleBar from '../TitleBar'
//import vacationModel from '../../models/vacationModel'
import hrequestModel from '../../models/hrequestModel'

import SelectComponent from '../Components/SelectComponent'
import SelectLink from '../Components/SelectLink'
//import Loading from '../Loading'

import dayjs from 'dayjs'


export default function HRequestEditor(props) {
    const themeObject = useTheme()
    //console.log(props)


    //const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const appConfig = useSelector((state) => state.appConfig)


    const hrModel = ((props.route.params instanceof Object) && (props.route.params.hasOwnProperty('model'))) ? props.route.params.model : new hrequestModel({}, userInfo.data)
    hrModel.insertUserInfo(userInfo.data)

    //console.log(hrModel.debug)


    //type
    const [type, setType] = useState(hrModel.data.type)
    const typeOptions = []
    if (hrModel.isEditable) {
        for (let i = 1; i <= 12; i++) {
            typeOptions.push({ v: i, label: hrequestModel.typeToDesc(i) })
        }
    }


    //comment
    const [comment, setComment] = useState(hrModel.data.comment)
    //hrcomment - not editable
    const [supcomment, setSupcomment] = useState(hrModel.data.supcomment)





    //datepicker
    const [date, setDate] = useState(hrModel.date ? hrModel.date.toDate() : false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const onDismiss = useCallback(() => {
        setDatePickerOpen(false)
    }, [setDatePickerOpen])

    const onConfirm = useCallback(
        ({ date }) => {
            if (typeof date === 'undefined') {
                date = false
            } else {
                date = dayjs(date).toDate()
            }
            setDatePickerOpen(false)
            setDate(date)
        },
        [setDatePickerOpen, setDate]
    )



    //timepickers
    const nowHHmm = { hours: parseInt(dayjs().format('HH')), minutes: parseInt(dayjs().format('mm')) }

    const [from_time, setFrom_time] = useState(hrModel.data.from_time !== null ? hrModel.fromTimeDisp : null)
    const from_time_disp = from_time === null ? nowHHmm : hrequestModel.timeObj(from_time)
    const [from_timePickerOpen, setFrom_timePickerOpen] = useState(false)
    const onFrom_timePickerDismiss = useCallback(() => {
        setFrom_timePickerOpen(false)
    }, [setFrom_timePickerOpen])

    const onFrom_timePickerConfirm = useCallback((newValue) => {
        setFrom_time(hrequestModel.timeDisp(newValue))
        setFrom_timePickerOpen(false)
    }, [setFrom_timePickerOpen])


    const [till_time, setTill_time] = useState(hrModel.data.till_time !== null ? hrModel.tillTimeDisp : null)
    const till_time_disp = till_time === null ? nowHHmm : hrequestModel.timeObj(till_time)
    const [till_timePickerOpen, setTill_timePickerOpen] = useState(false)
    const onTill_timePickerDismiss = useCallback(() => {
        setTill_timePickerOpen(false)
    }, [setTill_timePickerOpen])


    const onTill_timePickerConfirm = useCallback((newValue) => {
        setTill_timePickerOpen(false)
        setTill_time(hrequestModel.timeDisp(newValue))
    }, [setTill_timePickerOpen])



    //back type
    const [back_type, setBack_type] = useState(0)
    const back_typeOptions = [{ v: 0, label: localizer('none or specify later') }]
    if ((hrequestModel.backTypes[type]) && (hrModel.isEditable) && (hrModel.isDraft)) {
        for (let i = 1; i <= 11; i++) {
            if (hrequestModel.backTypes[type].includes(i)) {
                back_typeOptions.push({ v: i, label: hrequestModel.typeToDesc(i) })
            }
        }
    }


    //back datepicker
    const [back_date, setBack_date] = useState(false)
    const [back_datePickerOpen, setBack_datePickerOpen] = useState(false)

    const onBDDismiss = useCallback(() => {
        setBack_datePickerOpen(false)
    }, [setBack_datePickerOpen])

    const onBDConfirm = useCallback(
        ({ date }) => {
            if (typeof date === 'undefined') {
                date = false
            } else {
                date = dayjs(date).toDate()
            }
            setBack_datePickerOpen(false)
            setBack_date(date)
        },
        [setBack_datePickerOpen, setBack_date]
    )


    const [back_from_time, setBack_from_time] = useState(null)
    const back_from_time_disp = back_from_time === null ? nowHHmm : hrequestModel.timeObj(back_from_time)
    const [back_from_timePickerOpen, setBack_from_timePickerOpen] = useState(false)
    const onBack_from_timePickerDismiss = useCallback(() => {
        setBack_from_timePickerOpen(false)
    }, [setBack_from_timePickerOpen])

    const onBack_from_timePickerConfirm = useCallback((newValue) => {
        setBack_from_time(hrequestModel.timeDisp(newValue))
        setBack_from_timePickerOpen(false)
    }, [setBack_from_timePickerOpen])


    const [back_till_time, setBack_till_time] = useState(null)
    const back_till_time_disp = back_till_time === null ? nowHHmm : hrequestModel.timeObj(back_till_time)
    const [back_till_timePickerOpen, setBack_till_timePickerOpen] = useState(false)
    const onBack_till_timePickerDismiss = useCallback(() => {
        setBack_till_timePickerOpen(false)
    }, [setBack_till_timePickerOpen])


    const onBack_till_timePickerConfirm = useCallback((newValue) => {
        setBack_till_timePickerOpen(false)
        setBack_till_time(hrequestModel.timeDisp(newValue))
    }, [setBack_till_timePickerOpen])


    const [back_comment, setBack_comment] = useState(null)

    const [saving, setSaving] = useState(0)


    useEffect(() => {
        setBack_type(0)
        setBack_date(false)
        setBack_from_time(null)
        setBack_till_time(null)
    }, [type])



    const saveAction = () => {

        if (saving === 0) {
            if (date === false) {
                Toast.show(localizer('Date is required'))
                return
            }

            if ((!from_time) || (!till_time)) {
                Toast.show(localizer('Time range is reqired'))
                return
            }

            if (parseInt(from_time.replace(':', '')) >= parseInt(till_time.replace(':', ''))) {
                Toast.show(localizer('Invalid time range'))
                return
            }

            if (back_type) {

                if (back_date === false) {
                    Toast.show( localizer('Give/get back date is required'))
                    return
                }

                if ((!back_from_time) || (!back_till_time)) {
                    Toast.show(localizer('Give/get back time range is reqired'))
                    return
                }

                if (parseInt(back_from_time.replace(':', '')) >= parseInt(back_till_time.replace(':', ''))) {
                    Toast.show(localizer('Invalid time range'))
                    return
                }
            }

            setSaving(1)

            let payload = {
                type,
                comment,
                ts_date: dayjs(date).format('YYYY-MM-DD'),
                from_time_str: from_time,
                till_time_str: till_time,
            }

            if (back_type) {
                payload = {
                    ...payload,
                    back_type,
                    back_comment,
                    ts_back: dayjs(back_date).format('YYYY-MM-DD'),
                    back_from_time_str: from_time,
                    back_till_time_str: till_time,
                }
            }

            hrModel.save(payload, userInfo.token).then(() => {
                if (hrModel.isDraft) {
                    Toast.show(localizer('Request placed'))
                } else {
                    Toast.show(localizer('Request updated'))
                }
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })
        }
    }


    const updateCommentAction = () => {

        if (saving === 0) {
            setSaving(1)

            hrModel.updateSupComment({ supcomment }, userInfo.token).then(() => {
                Toast.show(localizer('Supervisor comment updated'))
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })
        }
    }

    const cancelAction = () => {

        if (saving === 0) {
            setSaving(4)

            hrModel.cancel({ comment }, userInfo.token).then(() => {
                Toast.show(localizer('Request cancelled'))
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })
        }
    }

    const approveAction = (approved) => {

        if (saving === 0) {

            const payload = { supcomment }

            if (approved) {
                setSaving(3)
                payload.confirmed = 3
            } else {
                setSaving(2)
                payload.confirmed = 2
            }

            hrModel.approve(payload, userInfo.token).then(() => {
                if (approved) {
                    Toast.show(localizer('Request approved'))
                } else {
                    Toast.show(localizer('Request rejected'))
                }
                props.navigation.goBack()
            }).catch((error) => {
                setSaving(0)

                if (!error) {
                    error = localizer('Operation failed for unknown reason.')
                }

                Toast.show(error)
            })
        }
    }



    return (
        <TouchableWithoutFeedback onPress={() => { (Platform.OS === 'web') ? null : Keyboard.dismiss() }}>
            <View style={{ flex: 1 }}>
                <TitleBar title={hrModel.editorTitle}>
                    <Appbar.Action icon="arrow-left" onPress={() => { props.navigation.goBack() }} />
                </TitleBar>
                <ScrollView style={{ flex: 1 }}>
                    {
                        //type
                        hrModel.isEditable ?
                            (<SelectComponent value={type} options={typeOptions} setter={(v) => setType(v)} icon={hrequestModel.typeToIcon(type)} label={localizer("Request type")} emphasizeValue={true}  ></SelectComponent>)
                            :
                            (<SelectLink icon={hrequestModel.typeToIcon(type)} label={localizer("Request type")} sublabel={hrequestModel.typeToDesc(type)} emphasizeValue={true} disabled={true} ></SelectLink>)
                    }


                    <TextInput mode="outlined" label={localizer("Date")} style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 25, marginBottom: 0 }} value={date !== false ? dayjs(date).format('D MMM YYYY') : null} disabled={!hrModel.isEditable} onFocus={hrModel.isEditable ? () => setDatePickerOpen(true) : null} />



                    <View style={{ flexDirection: "row" }}>
                        {
                            (Platform.OS === 'web') ?
                                (
                                    <Button mode="outlined" style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 5, marginTop: 6, borderRadius: 4 }} textColor={themeObject.colors.onBackground}
                                        disabled={!hrModel.isEditable} onPress={hrModel.isEditable ? () => setFrom_timePickerOpen(true) : null} >{from_time ? from_time : localizer('From time')}</Button>
                                )
                                :
                                (
                                    <TextInput mode="outlined" label={localizer("From time")} style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 5, marginTop: 6 }}
                                        value={from_time} disabled={!hrModel.isEditable} onFocus={hrModel.isEditable ? () => setFrom_timePickerOpen(true) : null} />
                                )
                        }

                        {
                            (Platform.OS === 'web') ?
                                (
                                    <Button mode="outlined" style={{ flex: 1, margin: 15, marginLeft: 5, marginRight: 25, marginTop: 6, borderRadius: 4 }} textColor={themeObject.colors.onBackground}
                                        disabled={!hrModel.isEditable} onPress={hrModel.isEditable ? () => setTill_timePickerOpen(true) : null} >{till_time ? till_time : localizer('Till time')}</Button>
                                )
                                :
                                (
                                    <TextInput mode="outlined" label={localizer("Till time")} style={{ flex: 1, margin: 15, marginLeft: 5, marginRight: 25, marginTop: 6 }}
                                        value={till_time} disabled={!hrModel.isEditable} onFocus={hrModel.isEditable ? () => setTill_timePickerOpen(true) : null} />
                                )
                        }
                    </View>


                    <TextInput mode="outlined" label={localizer("Comment")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }} value={comment} disabled={hrModel.isEditable ? false : true} onChangeText={text => setComment(text)} />

                    {
                        supcomment !== null || hrModel.isManagedByMe ?
                            (<TextInput mode="outlined" label={localizer("Supervisor comment")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }} value={supcomment} disabled={!hrModel.isManagedByMe} onChangeText={text => setSupcomment(text)} />)
                            :
                            null

                    }

                    {
                        hrModel.data.hrcomment !== null ?
                            (
                                <TextInput mode="outlined" label={localizer("HR comment")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }} value={hrModel.data.hrcomment} disabled={true} />
                            )
                            :
                            null
                    }

                    {
                        back_typeOptions.length > 1 ?
                            (<SelectComponent value={back_type} options={back_typeOptions} setter={(v) => setBack_type(v)} icon={hrequestModel.typeToIcon(back_type)} label={localizer("Give/get back request")} emphasizeValue={true}  ></SelectComponent>)
                            :
                            null
                    }

                    {
                        back_type !== 0 ?
                            (
                                <TextInput mode="outlined" label={localizer("Give/get back date")} style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 25, marginBottom: 0 }} value={back_date !== false ? dayjs(back_date).format('D MMM YYYY') : null} onFocus={() => setBack_datePickerOpen(true)} />
                            )
                            :
                            null
                    }


                    <View style={{ flexDirection: "row" }}>
                        {
                            back_type === 0 ? null :
                                (Platform.OS === 'web') ?
                                    (
                                        <Button mode="outlined" style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 5, marginTop: 6, borderRadius: 4 }} textColor={themeObject.colors.onBackground} onPress={() => setBack_from_timePickerOpen(true)} >{back_from_time ? back_from_time : localizer('From time')}</Button>
                                    )
                                    :
                                    (
                                        <TextInput mode="outlined" label={localizer("From time")} style={{ flex: 1, margin: 15, marginLeft: 25, marginRight: 5, marginTop: 6 }}
                                            value={back_from_time} onFocus={() => setBack_from_timePickerOpen(true)} />
                                    )
                        }

                        {
                            back_type === 0 ? null :
                                (Platform.OS === 'web') ?
                                    (
                                        <Button mode="outlined" style={{ flex: 1, margin: 15, marginLeft: 5, marginRight: 25, marginTop: 6, borderRadius: 4 }} textColor={themeObject.colors.onBackground} onPress={() => setBack_till_timePickerOpen(true)} >{back_till_time ? back_till_time : localizer('Till time')}</Button>
                                    )
                                    :
                                    (
                                        <TextInput mode="outlined" label={localizer("Till time")} style={{ flex: 1, margin: 15, marginLeft: 5, marginRight: 25, marginTop: 6 }}
                                            value={back_till_time} onFocus={() => setBack_till_timePickerOpen(true)} />
                                    )
                        }
                    </View>

                    {
                        back_type === 0 ? null : (
                            <TextInput mode="outlined" label={localizer("Comment")} style={{ margin: 15, marginLeft: 25, marginRight: 25 }} value={back_comment} onChangeText={text => setBack_comment(text)} />
                        )
                    }

                    <View style={{ alignItems: "center", marginTop: 30, marginBottom: 50, width: "100%" }}>
                        {
                            hrModel.isEditable ?
                                hrModel.isDraft ?
                                    (<Button style={{ width: 200 }} icon="check" loading={saving ? true : false} mode="contained-tonal" onPress={saveAction} >{localizer('Place Request')}</Button>)
                                    :
                                    (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Button
                                                buttonColor={themeObject.colors.error}
                                                textColor={themeObject.colors.onError}
                                                style={{ width: 160, marginRight: 15 }}
                                                icon="close" loading={saving === 4 ? true : false}
                                                mode="contained"
                                                onPress={() => cancelAction()} >
                                                {localizer('Cancel')}
                                            </Button>
                                            <Button style={{ width: 160, marginLeft: 15 }} icon="check" loading={saving === 1 ? true : false} mode="contained-tonal" onPress={saveAction} >{localizer('Update')}</Button>
                                        </View>
                                    )
                                :
                                hrModel.isPendingToMe ?
                                    (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Button
                                                buttonColor={themeObject.colors.error}
                                                textColor={themeObject.colors.onError}
                                                style={{ width: 160, marginRight: 15 }}
                                                icon="close" loading={saving === 2 ? true : false}
                                                mode="contained"
                                                onPress={() => approveAction(false)} >
                                                {localizer('Reject')}
                                            </Button>
                                            <Button
                                                //buttonColor={themeObject.colors.primary}
                                                //textColor={themeObject.colors.onPrimary}
                                                style={{ width: 160, marginLeft: 15 }}
                                                icon="check" loading={saving === 3 ? true : false}
                                                mode="contained"
                                                onPress={() => approveAction(true)} >
                                                {localizer('Approve')}
                                            </Button>
                                        </View>
                                    )
                                    :
                                    hrModel.isManagedByMe ?
                                        (<Button style={{ width: 200 }} icon="comment-text-outline" loading={saving ? true : false} mode="contained-tonal" onPress={updateCommentAction} >{localizer('Update Comment')}</Button>)
                                        //
                                        :
                                        null
                        }
                    </View>

                </ScrollView>

                <View>
                    <DatePickerModal
                        locale={rnpdLocales[appConfig.data.locale].name}
                        mode="single"
                        visible={datePickerOpen}
                        onDismiss={onDismiss}
                        date={date ? date : false}
                        onConfirm={onConfirm}
                        label={localizer("Select date")}
                        saveLabel={localizer("Apply")}
                        animationType="none"
                        uppercase={false}
                        disableStatusBar={true}
                        disableStatusBarPadding={true}
                        //startLabel="from"
                        //endLabel="till"
                        validRange={{ startDate: dayjs(dayjs().subtract(5, 'day').format('YYYY-MM') + '-01').toDate(), endDate: dayjs(dayjs().format('YYYY') + '-12-31').toDate() }}
                    />

                    <DatePickerModal
                        locale={rnpdLocales[appConfig.data.locale].name}
                        mode="single"
                        visible={back_datePickerOpen}
                        onDismiss={onBDDismiss}
                        date={back_date ? back_date : false}
                        onConfirm={onBDConfirm}
                        label={localizer("Select date")}
                        saveLabel={localizer("Apply")}
                        animationType="none"
                        uppercase={false}
                        disableStatusBar={true}
                        disableStatusBarPadding={true}
                        //startLabel="from"
                        //endLabel="till"
                        validRange={{ startDate: dayjs(dayjs().subtract(5, 'day').format('YYYY-MM') + '-01').toDate(), endDate: dayjs(dayjs().format('YYYY') + '-12-31').toDate() }}
                    />



                    <TimePickerModal
                        //locale={rnpdLocales[appConfig.data.locale].name}
                        label={localizer('Select time')}
                        cancelLabel={localizer('Cancel')}
                        confirmLabel={localizer('Apply')}
                        visible={till_timePickerOpen}
                        onDismiss={onTill_timePickerDismiss}
                        onConfirm={onTill_timePickerConfirm}
                        uppercase={false}
                        animationType='none'
                        hours={till_time_disp.hours}
                        minutes={till_time_disp.minutes}
                    />

                    <TimePickerModal
                        //locale={rnpdLocales[appConfig.data.locale].name}
                        label={localizer('Select time')}
                        cancelLabel={localizer('Cancel')}
                        confirmLabel={localizer('Apply')}
                        visible={from_timePickerOpen}
                        onDismiss={onFrom_timePickerDismiss}
                        onConfirm={onFrom_timePickerConfirm}
                        uppercase={false}
                        animationType='none'
                        hours={from_time_disp.hours}
                        minutes={from_time_disp.minutes}
                    />

                    <TimePickerModal
                        //locale={rnpdLocales[appConfig.data.locale].name}
                        label={localizer('Select time')}
                        cancelLabel={localizer('Cancel')}
                        confirmLabel={localizer('Apply')}
                        visible={back_till_timePickerOpen}
                        onDismiss={onBack_till_timePickerDismiss}
                        onConfirm={onBack_till_timePickerConfirm}
                        uppercase={false}
                        animationType='none'
                        hours={back_till_time_disp.hours}
                        minutes={back_till_time_disp.minutes}
                    />

                    <TimePickerModal
                        //locale={rnpdLocales[appConfig.data.locale].name}
                        label={localizer('Select time')}
                        cancelLabel={localizer('Cancel')}
                        confirmLabel={localizer('Apply')}
                        visible={back_from_timePickerOpen}
                        onDismiss={onBack_from_timePickerDismiss}
                        onConfirm={onBack_from_timePickerConfirm}
                        uppercase={false}
                        animationType='none'
                        hours={back_from_time_disp.hours}
                        minutes={back_from_time_disp.minutes}
                    />
                </View>

            </View>
        </TouchableWithoutFeedback>
    )
}